import "./App.css";
import React, { useLayoutEffect, useState } from "react";
import { Container, Row, Col, Image, Card } from "react-bootstrap";
import { Link } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import { Toaster } from "react-hot-toast";

function App(props) {
  const dashboardItems = [
    {
      id: "my_mandi",
      url: "/my_mandi",
      image: "my-mandi.png",
      title: "My Mandi",
      title_i18: "menu_my_mandi",
    },
    {
      id: "my_godown",
      url: "/my_godown",
      image: "my-godown.png",
      title: "My Godown",
      title_i18: "menu_my_godown",
    },
    {
      id: "my_credit",
      url: "/my_credit",
      image: "my-credit.png",
      title: "My Credit",
      title_i18: "menu_my_credit",
    },
    {
      id: "quality_lab",
      url: "/labs",
      image: "quality-lab.png",
      title: "Quality Lab",
      title_i18: "menu_quality_labs",
    },
    {
      id: "my_account",
      url: "/my_account",
      image: "my-account.png",
      title: "My Account",
      title_i18: "menu_my_account",
    },
  ];

  const [size, setSize] = useState([0, 0]);
  useLayoutEffect(() => {
    function updateSize() {
      setSize([window.innerWidth, window.innerHeight]);
    }
    window.addEventListener("resize", updateSize);
    updateSize();
    return () => window.removeEventListener("resize", updateSize);
  }, []);

  let widgets = dashboardItems.map((widget, index) => {
    return (
      <Row
        key={index}
        className={"dashboard-item dashboard-item-" + index}
        id={"dashboard-item-" + widget.id}
        data-width={size[0]}
        data-height={size[1]}
      >
        <Link
          id={"dashboard-link-" + widget.id}
          to={widget.url}
          className="dashboard-link w-100"
          style={{ height: parseInt((size[1] - 162) / dashboardItems.length) }}
        >
          <Card
            id={"dashboard-card-" + widget.id}
            className="menu_card rounded-0"
            bg="none"
          >
            <Card.Img src={widget.image} />
            <Card.Body>
              <div className={"dashboard-item-wrapper"}>
                <Card.Title>
                  <FormattedMessage
                    id={widget.title_i18}
                    defaultMessage={widget.title}
                  />
                </Card.Title>
                <Card.Text
                  style={{ backgroundImage: 'url("arrow_right-black.svg"' }}
                >
                  &nbsp;
                </Card.Text>
              </div>
            </Card.Body>
          </Card>
        </Link>
      </Row>
    );
  });

  return (
    <Container fluid>
      <Row>
        <Container className={"dashboard-header"}>
          <Row className="my-4" style={{ textAlign: "center" }}>
            <Col>
              <Image src="grammadi.png" fluid />
            </Col>
          </Row>
        </Container>
        <Container className={"dashboard-content"}>
          <Toaster />
          {widgets}
        </Container>
      </Row>
    </Container>
  );
}

export default App;
