import React, { useState, useContext, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Form,
  Button,
  Popover,
  OverlayTrigger,
} from "react-bootstrap";
import { getGramMandiRates } from "../../../services/GramMandi";
import { useHistory } from "react-router-dom";
import { FormattedMessage, useIntl } from "react-intl";
import { Context } from "../../Layout/Default";
import { getQualityOptions } from "../../../services/lab";

function QualityLab(props) {
  const context = useContext(Context);
  let history = useHistory();
  let intl = useIntl();
  const currentLocale = intl.locale;

  const getTranslatedCropName = (crop) =>
    currentLocale === "en-IN" ? crop.name : crop.marathi_name;

  const [crops, setCrops] = useState({});
  const [cropId, setCropId] = useState(0);
  const [cropName, setCropName] = useState("");

  const [qualityOptions, setQualityOptions] = useState({});
  const [qualityGrades, setQualityGrades] = useState({});
  const [sampleWeight, setSampleWeight] = useState(0);
  const [qualityParameters, setQualityParameters] = useState({});
  const [qualityGradesValues, setQualityGradesValues] = useState({});

  useEffect(() => {
    context.updateNavigationTitle("Quality Lab");

    async function fetchCrops() {
      try {
        const result = await getGramMandiRates();
        return result;
      } catch (err) {
        context.setShowError(true);
      }
    }

    fetchCrops().then((res) => setCrops(res.data));
  }, []);

  useEffect(() => {
    async function fetchQualityOptions() {
      try {
        const result = await getQualityOptions(cropId);
        return result;
      } catch (err) {
        context.setShowError(true);
      }
    }

    if (cropId) {
      fetchQualityOptions().then((res) => {
        setQualityOptions(res.data.qualities);
        // if grades are available store them
        console.log("res.data", res.data);
        if ("grades" in res.data && Object.keys(res.data.grades).length > 0) {
          console.log("res.data", res.data.grades);
          setQualityGrades(res.data.grades);
        }
      });
    }
  }, [cropId]);

  const handleChooseOption = (e) => {
    let index = e.nativeEvent.target.selectedIndex;

    let crop_id = e.target.value;
    let crop_name = e.nativeEvent.target[index].dataset.label;

    setCropId(crop_id);
    setCropName(crop_name);

    localStorage.setItem("gramheet.lab.crop_id", crop_id);
    localStorage.setItem("gramheet.lab.crop_name", crop_name);
  };

  const updateSampleWeight = (weight) => {
    setSampleWeight(weight);
  };

  const updateQualityParameters = (opt, val) => {
    setQualityParameters({ ...qualityParameters, [opt]: val });
  };

  const popover = (
    <Popover id="popover-basic">
      <Popover.Title
        as="h5"
        onClick={(e) => {
          history.push("/labs/lab_faq");
        }}
      >
        <FormattedMessage
          id="info_click_to_know_more_about_lab"
          defaultMessage="Know more about Quality Testing"
        />
      </Popover.Title>
    </Popover>
  );

  return (
    <Container className="my-3">
      <Row>
        <Col className={"faq-wrapper mb-3"}>
          <OverlayTrigger
            trigger="click"
            placement="left"
            overlay={popover}
            defaultShow={true}
          >
            <Button variant="success">?</Button>
          </OverlayTrigger>
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <Form.Group controlId="formGridState">
            <Form.Label>
              <FormattedMessage
                id="label_select_crop"
                defaultMessage="Select Crop"
              />{" "}
            </Form.Label>
            <Form.Control
              as="select"
              onChange={handleChooseOption}
              defaultValue="choose"
            >
              <option value="choose" disabled>
                {intl.formatMessage({
                  id: "select",
                  label: "Select",
                })}
              </option>
              {"crops" in crops
                ? crops.crops.map((crop) => {
                    return (
                      <option
                        key={crop.id}
                        value={crop.id}
                        data-label={getTranslatedCropName(crop)}
                      >
                        {getTranslatedCropName(crop)}
                      </option>
                    );
                  })
                : ""}
            </Form.Control>
          </Form.Group>
        </Col>
        {(Object.keys(qualityOptions).length && (
          <Col>
            <Row>
              <Col md={12}>
                <Form
                  validated={true}
                  onSubmit={(e) => {
                    history.push({
                      pathname: "/labs/results",
                      crop_id: cropId,
                      crop_name: cropName,
                      qualityOptions: qualityOptions,
                      sampleWeight: sampleWeight,
                      qualityParameters: qualityParameters,
                      qualityGradesValues: qualityGradesValues,
                    });
                  }}
                >
                  <Form.Group controlId="formGroupSampleWeight">
                    <Form.Label>
                      <FormattedMessage
                        id="sample_weight"
                        defaultMessage="Sample Weight"
                      />
                    </Form.Label>
                    <Form.Control
                      type="number"
                      required
                      placeholder={
                        intl.formatMessage({
                          id: "label_sample_weight",
                          defaultMessage: "Sample Weight",
                        }) +
                        " " +
                        intl.formatMessage({
                          id: "enter",
                          defaultMessage: "Enter",
                        })
                      }
                      value={sampleWeight}
                      onChange={(e) => {
                        setSampleWeight(e.target.value);
                      }}
                    />
                  </Form.Group>
                  {Object.keys(qualityOptions).map((opt) => {
                    return (
                      <Form.Group
                        key={opt}
                        controlId={`formGroup${qualityOptions[opt]}`}
                      >
                        <Form.Label>
                          <FormattedMessage
                            id={context.formatSlug(qualityOptions[opt])}
                            defaultMessage={qualityOptions[opt]}
                          />
                        </Form.Label>
                        <Form.Control
                          type="number"
                          required={true}
                          placeholder={intl.formatMessage(
                            {
                              id: "fill_in_parameter",
                              defaultMessage: qualityOptions[opt],
                            },
                            {
                              parameter: intl.formatMessage({
                                id: context.formatSlug(qualityOptions[opt]),
                                defaultMessage: qualityOptions[opt],
                              }),
                            }
                          )}
                          onChange={(e) => {
                            setQualityParameters({
                              ...qualityParameters,
                              [opt]: e.target.value,
                            });
                          }}
                        />
                      </Form.Group>
                    );
                  })}
                  {(Object.keys(qualityGrades).length && (
                    <Row>
                      <Col md={12}>
                        {Object.keys(qualityGrades).map((opt) => {
                          return (
                            <Form key={`grades-${opt}`}>
                              <Form.Group
                                controlId={`formGroup-grades-${qualityGrades[opt]}`}
                              >
                                {console.log(
                                  context.formatSlug(qualityGrades[opt])
                                )}
                                <Form.Label>
                                  <FormattedMessage
                                    id={context.formatSlug(qualityGrades[opt])}
                                    defaultMessage={qualityGrades[opt]}
                                  />
                                </Form.Label>
                                <Form.Control
                                  type="number"
                                  required={true}
                                  placeholder={intl.formatMessage(
                                    {
                                      id: "fill_in_parameter",
                                      defaultMessage: qualityGrades[opt],
                                    },
                                    {
                                      parameter: intl.formatMessage({
                                        id: context.formatSlug(
                                          qualityGrades[opt]
                                        ),
                                        defaultMessage: qualityGrades[opt],
                                      }),
                                    }
                                  )}
                                  onChange={(e) => {
                                    setQualityGradesValues({
                                      ...qualityGradesValues,
                                      [opt]: e.target.value,
                                    });
                                  }}
                                />
                              </Form.Group>
                            </Form>
                          );
                        })}
                      </Col>
                    </Row>
                  )) ||
                    true}
                  <Form.Group
                    controlId="formGroupHistory"
                    style={{ textAlign: "center" }}
                  >
                    <Button size="block" variant="info" type="submit">
                      <FormattedMessage
                        id="button_submit"
                        defaultMessage="Submit"
                      />
                    </Button>
                  </Form.Group>
                </Form>
              </Col>
            </Row>
          </Col>
        )) ||
          true}
      </Row>
    </Container>
  );
}

export default QualityLab;
