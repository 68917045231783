import axios from "./api";

export const getQualityOptions = (id) => {
  return axios.get(`qualities/${id}`);
};

export const calculateQuality = (body) => {
  return axios.post(`lab.json`, body);
};

export const todaysPrice = (cropId) => {
  return axios.get(`todaysPrice/${cropId}`);
};
