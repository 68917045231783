import React, {useContext, useEffect, useState} from 'react';
import {Container, Row, Col, Table, Accordion, Card, Button, Form} from 'react-bootstrap';
import {FormattedMessage, useIntl} from 'react-intl';
import {useHistory} from 'react-router-dom';
import {getCreditRquest} from '../../../services/Credit';
import {sendOtp} from '../../../services/otp';
import {Context} from "../../Layout/Default";


function CreditRequest(props) {
	const context = useContext(Context);
	let history = useHistory();

	const [validated, setValidated] = useState(false);
	const [validateMaxLength, setValidateMaxLength] = useState()

	const crop_name = (props.location.crop_name) ? props.location.crop_name : localStorage.getItem('credit_crop_name');
	const storage_id = (props.location.storage_id) ? props.location.storage_id : localStorage.getItem('credit_storage_id');
	const storage_invoice = props.location.storage_invoice ? props.location.storage_invoice : localStorage.getItem('credit_storage_invoice');

	if (!localStorage.getItem('credit_crop_name')) {
		localStorage.setItem('credit_crop_name', crop_name)
	}

	if (!localStorage.getItem('credit_storage_id')) {
		localStorage.setItem('credit_storage_id', storage_id)
	}

	if (!localStorage.getItem('credit_storage_invoice')) {
		localStorage.setItem('credit_storage_invoice', storage_invoice)
	}

	// if (crop_name == "noname" || storage_id == 0) {
	//     props.history.push('/my_credit')
	// }

	const intl = useIntl();


	const [creditAmount, setCreditAmount] = useState()
	const [creditRequest, setCreditRequest] = useState([])


	useEffect( () => {
		context.updateNavigationTitle('Credit Request')

		async function fetchData() {
			try {
				const result = await getCreditRquest(storage_id)
				console.log(result)
				return result
			} catch (err) {
				context.setShowError(true)
			}
		}

		fetchData().then(res => setCreditRequest(res.data))
	}, [storage_id]);


	const handleCreditAmount = (e) => {

		if (e.target.value == 0) {
			setValidateMaxLength(true)
		} else {
			setValidateMaxLength(false)
		}
		if (e.target.value > creditRequest.available) {
			setCreditAmount(creditRequest.available)
		} else {
			setCreditAmount(Math.ceil(e.target.value / 100) * 100)
		}
	}

	const handeOnInputChange = (e) => {
		if (e.target.value > creditRequest.available) {
			setCreditAmount(creditRequest.available)
		} else {
			setCreditAmount(e.target.value)
		}
	}

	const handleCreditRequest = async () => {
		const otpResult = await sendOtp("91" + context.getFarmerMobile(), "credit")
		console.log(otpResult)
		if (otpResult.data.type == "success") {
			history.push({
				pathname: '/verify',
				from: 'credit',
				mobile: context.getFarmerMobile(),
				creditReqObj: {
					"total_credit": creditRequest.credit_limit,
					"used_credit": creditRequest.credit_limit - creditRequest.available,
					"available_credit": creditRequest.available,
					"storage_id": storage_id,
					"code": "NEW",
					"requested_credit": creditAmount,
					"fees_percent": creditRequest.fees_rate,
					"fees_rupees": (creditRequest.fees_rate * creditAmount) / 100,
					"interest_rate": creditRequest.interest_rate,
					"status": "pending",
					"organisation_id": creditRequest.organisation_id,
					"farmer_id": context.getFarmerId(),
					"cost_per_qtl": creditRequest.cost_per_qtl,
					"total_price": creditRequest.total_price
				}
			})
		}
	}

	const handleSubmit = (event) => {
		const form = event.currentTarget;
		console.log(creditAmount, creditRequest.available)
		if (form.checkValidity() === false) {
			event.preventDefault();
			event.stopPropagation();
		}

		if (creditAmount != 0 && creditAmount < creditRequest.available) {
			setValidated(true);
			handleCreditRequest()
		} else {
			event.preventDefault();
			event.stopPropagation();
			setValidateMaxLength(true);
		}
	};


	return (
		<Container fluid className="my-3">
			<Row style={{margin: '0 auto'}}>
				<Col>
					{/* <Jumbotron style={{ textAlign: 'center' }}>
                        <h3>
                            <FormattedMessage
                                id="button_credit_request"
                                defaultMessage="Available Credit"
                            />
                        </h3>
                    </Jumbotron> */}
				</Col>
			</Row>
			{(creditRequest.success) ? (<Row>
				<Col>
					<Accordion defaultActiveKey="0">
						<Card>
							<Card.Body>
								<Table>
									<thead>
									<tr>
										<th colSpan="2" style={{textAlign: 'center'}}>
											<FormattedMessage
												id="label_credit_receipt_no"
												defaultMessage="Credit Receipt No"
											/> {storage_invoice}
										</th>
									</tr>
									</thead>
									<tbody>
									<tr>
										<td><FormattedMessage
											id="table_cell_crop_name"
											defaultMessage="Crop Name"
										/></td>
										<td><FormattedMessage
											id={crop_name}
											defaultMessage={crop_name}
										/></td>
									</tr>
									<tr>
										<td><FormattedMessage
											id="table_cell_total_credit_available"
											defaultMessage="Total Credit available"
										/></td>
										<td>{creditRequest.available}</td>
									</tr>
									<tr>
										<td><FormattedMessage
											id="table_cell_current_interest_rate"
											defaultMessage="Current Interest Rate"
										/></td>
										<td>{creditRequest.interest_rate}% <FormattedMessage
											id="table_cell_per_month"
											defaultMessage="Per Year"
										/></td>
									</tr>
									<tr>
										<td><FormattedMessage
											id="table_cell_current_fees_rate"
											defaultMessage="Current Fees Rate"
										/></td>
										<td>{creditRequest.fees_rate}% <FormattedMessage
											id="table_cell_once"
											defaultMessage="once"
										/></td>
									</tr>
									</tbody>
								</Table>
							</Card.Body>

						</Card>
					</Accordion>
				</Col>
			</Row>) : null}
			{(creditRequest.success) ? (
				<Row className="mt-3" style={{textAlign: 'center'}}>

					<Col>
						<Form noValidate validated={validated}>
							<Form.Row>

								<Form.Group as={Col} controlId="formGridState">
									<Form.Label><FormattedMessage
										id="label_credit_amount"
										defaultMessage="Credit Amount"
									/> &nbsp;
										<FormattedMessage
											id="enter"
											defaultMessage="Enter"
										/>
									</Form.Label>
									<Form.Control isInvalid={validateMaxLength} required type="number"
									              placeholder={intl.formatMessage({
										              id: 'placeholder_enter_in_multiple_of_hundred',
										              defaultMessage: 'Enter amount in multiples of 100'
									              })}
									              onBlur={e => handleCreditAmount(e)} value={creditAmount}
									              onChange={handeOnInputChange}
									/>
									<Form.Control.Feedback type="invalid">
										Please enter valid amount!
									</Form.Control.Feedback>
								</Form.Group>
							</Form.Row>

							<Button variant="info" block
							        onClick={handleSubmit}
							><FormattedMessage
								id="button_raise_credit_request"
								type='submit'
								defaultMessage="Raise Credit Request"
							/></Button>
						</Form>

					</Col>
				</Row>) : null}

		</Container>
	)
}

export default CreditRequest;
