import React, {useState, useEffect, useContext} from 'react';
import {Container, Row, Col, Table, Alert, Button} from 'react-bootstrap'
import {useHistory} from 'react-router-dom';
import {FormattedMessage, useIntl} from 'react-intl';
import {createSellRequest} from '../../../services/MyGodown'
import {sendOtp} from '../../../services/otp'
import {Context} from "../../Layout/Default";


function SellStorage(props) {
	const context = useContext(Context);
	let history = useHistory();
	let intl = useIntl();

	const [data, setData] = useState({});
	const [show, setShow] = useState(false);

	useEffect( () => {
		context.updateNavigationTitle('Sales Receipt Preview')

		if (props.location.storage_id) {

			async function fetchData() {
				try {
					const result = await createSellRequest(props.location.storage_id)

					return result
				} catch (err) {
					context.setShowError(true)
				}
			}

			fetchData().then(result => {
				setData(result.data)
				if (result.data.success) {
					setShow(true)

				} else {
					setShow(false)
				}
			})
		}
	}, []);

	const handeSellClick = async () => {
		try {
			const otpResult = await sendOtp("91" + context.getFarmerMobile(), "sell")
			console.log(otpResult)
			if (otpResult.data.type === "success") {
				history.push({
					pathname: '/verify',
					from: 'sell',
					mobile: context.getFarmerMobile(),
					storage_id: props.location.storage_id
				})
			}
		} catch (err) {
			context.setShowError(true)
		}
	}

	return (
		<Container className={"my-3"}>
			<Row className={!show ? 'd-block' : 'd-none'}>
				<Col>
					<Alert variant="danger">
						<p>
							<FormattedMessage
								id="storage_warning_price_not_updated_today"
								defaultMessage="Price is yet to be updated for today. Usual time for price update is 12 PM to 1 PM. Please try after 12 PM."
							/>

						</p>
						<hr/>
					</Alert>
				</Col>
			</Row>
			<Row className={show ? 'd-block' : 'd-none'}>
				<Col>
					<Table stripped="true" bordered>
						<tbody>

						<tr>
							<td>
								<FormattedMessage
									id="table_cell_crop_name"
									defaultMessage="Crop Name"
								/>
							</td>
							<td>
								<FormattedMessage
									id={data.crop_name ? context.formatSlug(data.crop_name) : "crop"}
									defaultMessage={data.crop_name}
								/>
							</td>
						</tr>

						<tr>
							<td>
								<FormattedMessage
									id="weight_qty"
									defaultMessage="Quantity"
								/>
							</td>
							<td>
								{data.quantity ? context.formatWeight(intl, data.quantity) : ""}
							</td>
						</tr>

						<tr>
							<td>
								<FormattedMessage
									id="table_cell_date_of_cell"
									defaultMessage="Date of Sale"
								/>
							</td>
							<td>
								{data.date_of_sale ? data.date_of_sale : ""}
							</td>
						</tr>

						<tr>
							<td>
								<FormattedMessage
									id="table_cell_todays_price_per_qtl"
									defaultMessage="Today’s Price per Qtl"
								/>
							</td>
							<td>
								{intl.formatNumber(parseFloat(data.todays_price).toFixed(0))}
							</td>
						</tr>

						<tr>
							<td>
								<FormattedMessage
									id="table_cell_bonus_per_qtl"
									defaultMessage="Bonus per Qtl"
								/>
							</td>
							<td>
								{intl.formatNumber(parseFloat(data.bonus).toFixed(0))}
							</td>
						</tr>

						<tr>
							<td>
								<FormattedMessage
									id="table_cell_deduction_by_quality"
									defaultMessage="Deduction per Qtl"
								/>
							</td>
							<td>
								{intl.formatNumber(parseFloat(data.deduction_amount).toFixed(0))}
							</td>
						</tr>

						<tr>
							<td>
								<FormattedMessage
									id="table_cell_final_price_offered"
									defaultMessage="Final Price Offered"
								/>
							</td>
							<td>
								{intl.formatNumber(parseFloat(data.received_rate).toFixed(0))}
							</td>
						</tr>

						<tr style={{backgroundColor: 'lightgreen'}}>
							<td>
								<FormattedMessage
									id="table_cell_total_amount"
									defaultMessage="Total Amount"
								/>
							</td>
							<td>
								{intl.formatNumber(parseFloat(data.total_amount).toFixed(0))}
							</td>
						</tr>

						<tr>
							<td>
								<FormattedMessage
									id="table_cell_credit_amount"
									defaultMessage="Credit amount"
								/>
							</td>
							<td>
								{intl.formatNumber(parseFloat(data.total_credit_amount).toFixed(0))}
							</td>
						</tr>

						<tr>
							<td>
								<FormattedMessage
									id="table_cell_interest_amount"
									defaultMessage="Interest amount"
								/>
							</td>
							<td>
								{intl.formatNumber(parseFloat(data.total_interest_amount).toFixed(0))}
							</td>
						</tr>

						<tr>
							<td>
								<FormattedMessage
									id="table_cell_processing_fees"
									defaultMessage="Processing Fees"
								/>
							</td>
							<td>
								{intl.formatNumber(parseFloat(data.total_processing_fees).toFixed(0))}
							</td>
						</tr>

						<tr>
							<td>
								<FormattedMessage
									id="table_cell_storage_charges"
									defaultMessage="Storage charges"
								/>
							</td>
							<td>
								{intl.formatNumber(parseFloat(data.storage_charges).toFixed(0))}
							</td>
						</tr>

						<tr style={{backgroundColor: 'lightgreen'}}>
							<td>
								<FormattedMessage
									id="table_cell_final_payable_amount"
									defaultMessage="Final Payable Amount"
								/>
							</td>
							<td>
								{intl.formatNumber(parseFloat(data.payable_amount).toFixed(0))}
							</td>
						</tr>
						</tbody>
					</Table>
				</Col>
			</Row>
			<Row className={show ? 'd-block' : 'd-none'}>
				<Col>
					<Button variant="success" block onClick={handeSellClick}>
						<FormattedMessage
							id="my_sell_click_to_sell"
							defaultMessage="Click here to Sell"
						/>
					</Button>
				</Col>
			</Row>
		</Container>)
}

export default SellStorage;
