import React, { useState, useContext, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Alert,
  Table,
  Card,
  Accordion,
} from "react-bootstrap";
import { calculateQuality, todaysPrice } from "../../../services/lab";
import { FormattedMessage } from "react-intl";
import { Context } from "../../Layout/Default";

function LabResult(props) {
  const context = useContext(Context);

  const [result, setResult] = useState({});
  const [cropDate, setCropDate] = useState(false);
  const [rateAvailable, setRateAvailable] = useState(false);

  useEffect(() => {
    context.updateNavigationTitle("Quality Analysis");

    async function fetchData() {
      try {
        const result = await calculateQuality({
          quality_values: props.location.qualityParameters,
          grade_values: props.location.qualityGradesValues,
          sample_weight: props.location.sampleWeight,
          crop_id: localStorage.getItem("gramheet.lab.crop_id"),
        });

        return result;
      } catch (err) {
        context.setShowError(true);
      }
    }

    fetchData().then((res) => {
      setResult(res.data);
      setRateAvailable(res.data ? res.data.todays_price : "");
      setCropDate(res.data ? res.data.price_date : "");
    });
  }, [setResult, cropDate]);

  return (
    <Container fluid className="my-3">
      <Row className="text-center">
        <Col>
          <h5>
            <FormattedMessage
              id={props.location.crop_name.toLowerCase()}
              defaultMessage={props.location.crop_name}
            />
          </h5>
        </Col>
      </Row>
      <Row>
        <Col>
          <Accordion defaultActiveKey="1">
            {"quality_calculation" in result
              ? result.quality_calculation.map((res, _i) => {
                  const parameterSlug = context.formatSlug(res.name);

                  return (
                    <Card key={`qality_${res.quality_id}_key`}>
                      <Accordion.Toggle as={Card.Header} eventKey={`${_i + 1}`}>
                        <FormattedMessage
                          id={context.formatSlug(res.name)}
                          defaultMessage={res.name}
                        />
                      </Accordion.Toggle>
                      <Accordion.Collapse eventKey={`${_i + 1}`}>
                        <Table striped="true" bordered>
                          <tbody>
                            <tr>
                              <td>
                                <FormattedMessage
                                  id="table_cell_standard_value"
                                  defaultMessage="Standard Value"
                                />
                              </td>
                              <td>{res.standard_value}</td>
                            </tr>
                            {parameterSlug != "moisture" ? (
                              <tr>
                                <td>
                                  <FormattedMessage
                                    id="weight_in_gm"
                                    defaultMessage="Weight (in gm)"
                                  />
                                </td>
                                <td>{res.entered_value}</td>
                              </tr>
                            ) : (
                              ""
                            )}
                            <tr>
                              <td>
                                <FormattedMessage
                                  id="table_cell_percentage"
                                  defaultMessage="Percentage"
                                />
                              </td>
                              <td>{parseFloat(res.per).toFixed(2)}%</td>
                            </tr>
                            <tr>
                              <td>
                                <FormattedMessage
                                  id="rate_per_qtl"
                                  defaultMessage="Damaged produce per QTL (in kg)"
                                />
                              </td>
                              <td>{res.bad_per_qtl}</td>
                            </tr>
                          </tbody>
                        </Table>
                        {/* </Card.Body> */}
                      </Accordion.Collapse>
                    </Card>
                  );
                })
              : ""}

            {"grade_calculation" in result
              ? result.grade_calculation.map((res, _i) => {
                  const parameterSlug = context.formatSlug(res.grade_name);

                  return (
                    <Card key={`qality_${res.grade_id}_key`}>
                      <Accordion.Toggle as={Card.Header} eventKey={`${_i + 1}`}>
                        {console.log("parameterSlug", parameterSlug)}
                        <FormattedMessage
                          id={context.formatSlug(res.grade_name)}
                          defaultMessage={res.grade_name}
                        />
                      </Accordion.Toggle>
                      <Accordion.Collapse eventKey={`${_i + 1}`}>
                        <Table striped="true" bordered>
                          <tbody>
                            <tr>
                              <td>
                                <FormattedMessage
                                  id="table_cell_grade_rate"
                                  defaultMessage="Grade Rate"
                                />
                              </td>
                              <td>{res.grade_rate}</td>
                            </tr>
                            <tr>
                              <td>
                                <FormattedMessage
                                  id="grade_weight_in_gm"
                                  defaultMessage="Weight (in gm)"
                                />
                              </td>
                              <td>{res.value}</td>
                            </tr>
                            <tr>
                              <td>
                                <FormattedMessage
                                  id="grade_percentage"
                                  defaultMessage="Percentage"
                                />
                              </td>
                              <td>{parseFloat(res.percentage).toFixed(2)}%</td>
                            </tr>
                            <tr>
                              <td>
                                <FormattedMessage
                                  id="table_cell_grade_recv_rate"
                                  defaultMessage="Grade Received Rate"
                                />
                              </td>
                              <td>{res.grade_received_rate}</td>
                            </tr>
                          </tbody>
                        </Table>
                        {/* </Card.Body> */}
                      </Accordion.Collapse>
                    </Card>
                  );
                })
              : ""}
          </Accordion>
        </Col>
      </Row>
      <Row className="mt-3">
        <Col>
          <Table striped>
            <tbody>
              <tr>
                <th colSpan={2}>
                  <FormattedMessage
                    id="price_details_per_qtl"
                    defaultMessage="Price Details (per QTL)"
                  />
                </th>
              </tr>
              <tr>
                <td>
                  <FormattedMessage
                    id="table_cell_sample_weight"
                    defaultMessage="Sample Weight"
                  />
                </td>
                <td>{result.sample_weight}</td>
              </tr>
              <tr>
                <td>
                  <FormattedMessage
                    id="table_cell_rate"
                    defaultMessage="Rate"
                  />
                </td>
                <td>{result.rate}</td>
              </tr>
              <tr>
                <td>
                  <FormattedMessage
                    id="table_cell_bonus"
                    defaultMessage="Bonus"
                  />
                </td>
                <td>{result.bonus}</td>
              </tr>
              <tr>
                <td>
                  <FormattedMessage
                    id="table_cell_deduction"
                    defaultMessage="Deduction"
                  />
                </td>
                <td>{result.deduction}</td>
              </tr>
              <tr className={"font-weight-bold"}>
                <td>
                  <FormattedMessage
                    id="received_rate"
                    defaultMessage="Received Rate"
                  />
                </td>
                <td>{result.receive_rate}</td>
              </tr>
            </tbody>
          </Table>
          <Alert variant="warning" className={cropDate ? "d-block" : "d-none"}>
            <FormattedMessage
              id="lab_result_rate_is_of_date"
              defaultMessage="Calculations are based on the rate of {date}."
              values={{
                date: cropDate,
              }}
            />
          </Alert>
        </Col>
      </Row>
    </Container>
  );
}

export default LabResult;
