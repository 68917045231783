import axios from "axios";

const axiosInstance = axios.create({
  //baseURL: "http://portal.gramheet.com/api/"
  baseURL: "http://ghportal.gramheet.com/api/",
});

axiosInstance.interceptors.request.use(
  function (config) {
    const token = localStorage.getItem("token");
    if (token)
      config.headers.Authorization =
        "Token 263484033.d93c3e4.87257f091333457ca7be16ba271a50d7";
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

export default axiosInstance;
