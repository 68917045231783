import React, { useContext, useEffect, useState } from "react";
import { Chart } from "react-charts";
import ResizableBox from "../../ResizableBox";
import { Container, Row, Col, Form } from "react-bootstrap";
import { FormattedMessage, useIntl } from "react-intl";
import { getPriceByFromToDate } from "../../../services/GramMandi";
import moment from "moment";
import { Context } from "../../Layout/Default";

function TrendChart(props) {
  const context = useContext(Context);
  const intl = useIntl();

  const [chartData, setChartData] = useState([]);

  const [cropName] = useState(
    localStorage.getItem("gram_mandi_crop_name")
      ? localStorage.getItem("gram_mandi_crop_name")
      : props.location.crop_name
  );
  const [cropId] = useState(
    localStorage.getItem("gram_mandi_crop_id")
      ? localStorage.getItem("gram_mandi_crop_id")
      : props.location.crop_id
  );

  const data = [
    {
      label: "Price",
      data: chartData,
    },
  ];

  const axes = React.useMemo(
    () => [
      { primary: true, type: "ordinal", position: "bottom" },
      { type: "linear", position: "right" },
    ],
    []
  );

  const series = React.useMemo(
    () => ({
      showPoints: true,
    }),
    []
  );

  const getCurrentWeek = () => {
    moment.locale("en");
    const weekStart = moment().startOf("week");
    const weekEnd = moment().endOf("week");
    return {
      start: moment(weekStart).format("YYYY-MM-DD"),
      end: moment(weekEnd).format("YYYY-MM-DD"),
    };
  };

  const [dateData, setDateData] = useState({
    start: getCurrentWeek().start,
    end: getCurrentWeek().end,
  });

  useEffect(() => {
    context.updateNavigationTitle(
      intl.formatMessage({
        id: "trend_chart",
        defaultMessage: "Trend of Price Change",
      })
    );

    //console.log(getCurrentWeek())

    async function fetchData() {
      let chartResult = [];

      try {
        const result = await getPriceByFromToDate(
          cropId,
          dateData.start,
          dateData.end
        );

        if ("price_list" in result.data) {
          Object.keys(result.data.price_list).forEach((e, _i) => {
            result.data.price_list[e].forEach((crop) => {
              chartResult.push([
                moment(crop.date, "DD-MM-YYYY").format("MMM D"),
                parseFloat(crop.price),
              ]);
            });
          });
        }
        console.log(chartResult);

        return chartResult;

        //setChartData(chartResult)
      } catch (err) {
        context.setShowError(true);
      }

      return null;
    }

    fetchData().then((r) => {
      setChartData(r);
      document.getElementsByClassName("react-resizable")[0].style.width =
        document.getElementsByClassName("chart-container")[0].style.width;
    });
  }, [dateData]);

  const getLastWeek = () => {
    moment.locale("en");
    const startOfLastWeek = moment().subtract(1, "weeks").startOf("isoWeek");
    const endOfLastWeek = moment().subtract(1, "weeks").endOf("isoWeek");
    return {
      start: moment(startOfLastWeek).format("YYYY-MM-DD"),
      end: moment(endOfLastWeek).format("YYYY-MM-DD"),
    };
  };

  const getCurrentMonth = () => {
    moment.locale("en");
    const startOfCurrentMonth = moment()
      .startOf("month")
      .format("YYYY-MM-DD hh:mm");
    const endOfCurrentMonth = moment()
      .endOf("month")
      .format("YYYY-MM-DD hh:mm");
    return {
      start: moment(startOfCurrentMonth).format("YYYY-MM-DD"),
      end: moment(endOfCurrentMonth).format("YYYY-MM-DD"),
    };
  };

  const getLastMonth = () => {
    moment.locale("en");
    const startOfPreviousMonth = moment()
      .subtract(1, "months")
      .startOf("month");
    const endOfPreviousMonth = moment().subtract(1, "months").endOf("month");
    return {
      start: moment(startOfPreviousMonth).format("YYYY-MM-DD"),
      end: moment(endOfPreviousMonth).format("YYYY-MM-DD"),
    };
  };

  console.log(props);

  const handleTrendOnChange = (e) => {
    switch (e.target.value) {
      case "current_week":
        setDateData({
          start: getCurrentWeek().start,
          end: getCurrentWeek().end,
        });
        setChartData([]);
        break;
      case "last_week":
        setDateData({ start: getLastWeek().start, end: getLastWeek().end });
        setChartData([]);
        break;
      case "this_month":
        setDateData({
          start: getCurrentMonth().start,
          end: getCurrentMonth().end,
        });
        setChartData([]);
        break;
      case "last_month":
        setDateData({ start: getLastMonth().start, end: getLastMonth().end });
        setChartData([]);
        break;
      default:
        break;
    }
  };

  return (
    <Container className="my-3">
      {cropName ? (
        <Row className="text-center">
          <Col>
            <h3>
              <FormattedMessage
                id={cropName.toLowerCase()}
                defaultMessage={cropName}
              />
            </h3>
          </Col>
        </Row>
      ) : (
        ""
      )}

      <Row className="mt-3">
        <Col>
          <Form.Group controlId="ControlSelect1">
            <Form.Label>
              <FormattedMessage
                id={"select_duration"}
                defaultMessage={"Select Duration"}
              />
            </Form.Label>
            <Form.Control
              as="select"
              onChange={(e) => {
                handleTrendOnChange(e);
              }}
            >
              <option value="current_week">
                {intl.formatMessage({
                  id: "current_week",
                  defaultMessage: "Current Week",
                })}
              </option>
              <option value="last_week">
                {intl.formatMessage({
                  id: "last_week",
                  defaultMessage: "Last Week",
                })}
              </option>
              <option value="this_month">
                {intl.formatMessage({
                  id: "this_month",
                  defaultMessage: "Current Month",
                })}
              </option>
              <option value="last_month">
                {intl.formatMessage({
                  id: "last_month",
                  defaultMessage: "Last Month",
                })}
              </option>
            </Form.Control>
          </Form.Group>
        </Col>
      </Row>

      <Row className="mt-3">
        <Col className={"chart-container"}>
          <ResizableBox className={"chart-wrapper"}>
            <Chart data={data} axes={axes} series={series} tooltip />
          </ResizableBox>
        </Col>
      </Row>
    </Container>
  );
}

export default TrendChart;
