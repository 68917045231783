import { Switch, Route, Redirect } from "react-router-dom";

import Home from "./components/Pages/Home";
import Login from "./components/Pages/Login";
import Register from "./components/Pages/Register";
import Authenticate from "./components/Pages/Authenticate";
import FPO from "./components/Pages/FPO";
import App from "./App";

import GramMandi from "./components/Pages/GramMandi/GramMandi";
import PriceHistory from "./components/Pages/GramMandi/PriceHistory";
import TrendChart from "./components/Pages/GramMandi/TrendChart";

import MyGodown from "./components/Pages/Storage/MyGodown";
import Storage from "./components/Pages/Storage/storage";
import StorageView from "./components/Pages/Storage/storageview";
import SellStorage from "./components/Pages/Storage/SellStorage";
import StorageFaq from "./components/Pages/Storage/StorageFaq";

import MyCredit from "./components/Pages/Credit/MyCredit";
import CreditRequest from "./components/Pages/Credit/CreditRequest";
import CreditHistory from "./components/Pages/Credit/CreditHistory";
import CreditFaq from "./components/Pages/Credit/CreditFaq";

import QualityLab from "./components/Pages/Labs/QualityLab";
import LabResultOption from "./components/Pages/Labs/LabsResultOption";
import LabResult from "./components/Pages/Labs/LabResult";
import LabFaq from "./components/Pages/Labs/LabFaq";

import MyAccount from "./components/Pages/Account/MyAccount";
import EditAccount from "./components/Pages/Account/Edit";
import ChangeLanguage from "./components/Pages/Account/ChangeLanguage";
import SellHistory from "./components/Pages/Account/SellHistory";

const routes = [
  {
    path: "/dashboard",
    component: App,
  },
  {
    path: "/mandi",
    component: GramMandi,
    routes: [
      {
        path: "/mandi/my_mandi",
        component: GramMandi,
      },
      {
        path: "/mandi/history",
        component: PriceHistory,
      },
      {
        path: "/mandi/trend",
        component: TrendChart,
      },
    ],
  },

  {
    path: "/godown",
    component: MyGodown,
    routes: [
      {
        path: "/godown/my_godown",
        component: MyGodown,
      },
      {
        path: "/storages/:farmer_id/:crop_id/:crop_name",
        component: PriceHistory,
      },
      {
        path: "/godown/trend",
        component: TrendChart,
      },
    ],
  },
];

export default function Routes() {
  return (
    <Switch>
      <Route path="/home" component={Home} />
      <Route path="/login" component={Login} />
      <Route path="/registration" component={Register} />
      <Route path="/verify" component={Authenticate} />
      <Route path="/fpo" component={FPO} />
      <Route path="/dashboard" component={App} />

      <Route exact path="/my_mandi" component={GramMandi} />
      <Route path="/my_mandi/price_history" component={PriceHistory} />
      <Route path="/my_mandi/trend" component={TrendChart} />

      <Route exact path="/my_godown" component={MyGodown} />
      <Route
        path="/my_godown/storages/:farmer_id/:crop_id/:crop_name"
        component={Storage}
      />
      <Route
        path="/my_godown/storage/view/:storage_id/"
        component={StorageView}
      />
      <Route path="/my_godown/sell_storage" component={SellStorage} />
      <Route path="/my_godown/storage_faq" component={StorageFaq} />

      <Route exact path="/my_credit" component={MyCredit} />
      <Route path="/my_credit/request" component={CreditRequest} />
      <Route path="/my_credit/history" component={CreditHistory} />
      <Route path="/my_credit/credit_faq" component={CreditFaq} />

      <Route exact path="/labs" component={QualityLab} />
      <Route path="/labs/options" component={LabResultOption} />
      <Route path="/labs/results" component={LabResult} />
      <Route path="/labs/lab_faq" component={LabFaq} />

      <Route exact path="/my_account" component={MyAccount} />
      <Route path="/my_account/edit" component={EditAccount} />
      <Route path="/my_account/change_language" component={ChangeLanguage} />
      <Route path="/my_account/sell_history" component={SellHistory} />

      <Redirect to="/home" />
    </Switch>
  );
}
