import React, { useContext } from "react";
import { Card, Col } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { useIntl, FormattedMessage } from "react-intl";
import { Context } from "../Layout/Default";

function Corp(props) {
  let history = useHistory();
  let intl = useIntl();
  const context = useContext(Context);
  const currentLocale = intl.locale;

  const getTranslatedCropName = (crop) =>
    currentLocale === "en-IN" ? crop.name : crop.marathi_name;

  const crop = props.crop;

  return (
    <Col className={"crop-item mt-3"} sm={12}>
      <Card
        className={"crop-card"}
        bg="light"
        style={{ borderRadius: "5px" }}
        onClick={(e) => {
          localStorage.setItem("gram_mandi_crop_id", crop.id);
          console.log(getTranslatedCropName(crop));
          localStorage.setItem(
            "gram_mandi_crop_name",
            getTranslatedCropName(crop)
          );
          history.push({
            pathname: "/my_mandi/price_history/" + crop.id,
            crop_id: crop.id,
            crop_name: getTranslatedCropName(crop),
            show_price_histroy: crop?.crop_grades?.length > 0 ? false : true,
            grade_data: crop.crop_grades,
          });
        }}
      >
        <div
          className="crop-img"
          style={{ backgroundImage: `url('${props.image_base + crop.image}')` }}
        ></div>
        {/* <Card.Img
          className={"crop-image"}
          src={props.image_base + crop.image}
        ></Card.Img> */}
        <Card.Body>
          {/* <Card.Title className={"crop-name"}>{intl.formatMessage({id: context.formatSlug(crop.name), defaultMessage: crop.name })}</Card.Title> */}
          <Card.Title className={"crop-name"}>
            {crop?.crop_grades?.length > 0 ? "" : getTranslatedCropName(crop)}
          </Card.Title>
          {crop?.crop_grades?.length > 0 ? (
            <span className="text-center d-block crop-price card-footer">
              <FormattedMessage
                id="click_to_view_crop_price"
                defaultMessage="Click to know the {name} prices"
                values={{
                  name: getTranslatedCropName(crop),
                }}
              />{" "}
            </span>
          ) : (
            <Card.Footer className="crop-price">
              {crop.crop_prices.length
                ? intl.formatNumber(crop.crop_prices[0].price) + " /-"
                : intl.formatMessage({
                    id: "price_unavailable",
                    defaultMessage:
                      "Today’s price will be declared after 12 PM",
                  })}
            </Card.Footer>
          )}
        </Card.Body>
      </Card>
    </Col>
  );
}

export default Corp;
