import React, {useContext, useEffect, useState} from 'react';
import {Container, Row, Col, Accordion, Card, Badge, Table} from 'react-bootstrap';
import {FormattedMessage, useIntl} from 'react-intl';
import {getCreditHistory} from "../../../services/Credit"
import {Context} from "../../Layout/Default";


function CreditHistory(props) {
	const context = useContext(Context);
	const intl = useIntl();

	const [creditHistoryData, setCreditHistoryData] = useState([])

	useEffect( () => {
		context.updateNavigationTitle('Credit History')

		async function fetchData() {
			try {
				const result = await getCreditHistory(context.getFarmerId())
				console.log(result)
				return result
			} catch (err) {
				context.setShowError(true)
			}
		}

		fetchData().then(res => setCreditHistoryData(res.data))

	}, [setCreditHistoryData]);

	const statusBadgeColorMapping = {
		'disbursed': 'success',
		'approved': 'success',
		'declined': 'danger',
		'pending': 'warning',
		'validated': 'success',
		'paid': 'success',
		'draft': 'info',
		'closed': 'danger'
	}

	const capitalize = function (string) {
		return string.charAt(0).toUpperCase() + string.slice(1);
	}

	return (
		<Container fluid className="my-3">
			<Row>
				<Col>
					{creditHistoryData.length ? creditHistoryData.map((creditObj, index) => {
						return <Accordion key={index} defaultActiveKey="0">
							<Card>
								<Accordion.Toggle as={Card.Header} eventKey="0" style={{textAlign: 'center'}}>
									<FormattedMessage
										id="credit_receipt_number"
										defaultMessage="Credit Receipt No."
									/> {creditObj.credit_request_no}
								</Accordion.Toggle>
								<Accordion.Collapse eventKey="0">
									<Card.Body>
										<Table size="sm" striped hover>
											<tbody>
											<tr>
												<td><FormattedMessage
													id="produce_name"
													defaultMessage="Produce Name"
												/></td>
												<td>{intl.formatMessage({
													id: context.formatSlug(creditObj.crop),
													value: creditObj.crop
												})}</td>
											</tr>

											<tr>
												<td><FormattedMessage
													id="table_cell_storage_invoice"
													defaultMessage="Storage Invoice #"
												/></td>
												<td>{creditObj.storage_invoice}</td>
											</tr>
											<tr>
												<td><FormattedMessage
													id="credit_amount"
													defaultMessage="Credit Amount"
												/></td>
												<td>{creditObj.amount}</td>
											</tr>
											<tr>
												<td><FormattedMessage
													id="table_cell_interest_rate"
													defaultMessage="Interest Rate"
												/></td>
												<td>{intl.formatNumber(creditObj.interest_rate)}% <FormattedMessage
													id="per_year"
													defaultMessage="per year"/></td>
											</tr>
											<tr>
												<td><FormattedMessage
													id="processing_fees"
													defaultMessage="Processing Fees"
												/></td>
												<td>{creditObj.fees}</td>
											</tr>
											<tr>
												<td><FormattedMessage
													id="credit_request_date"
													defaultMessage="Credit Request Date"
												/></td>
												<td>{creditObj.request_date}</td>
											</tr>
											<tr>
												<td><FormattedMessage
													id="status"
													defaultMessage="Status"
												/></td>
												<td><Badge
													variant={statusBadgeColorMapping[creditObj.status]}>{intl.formatMessage({
													id: creditObj.status,
													value: creditObj.status
												})}</Badge>{' '}
												</td>
											</tr>
											<tr>
												<td><FormattedMessage
													id="table_cell_date_of_credit_recived"
													defaultMessage="Date of credit recived"
												/></td>
												<td>{creditObj.payment_date}</td>
											</tr>
											<tr>
												<td><FormattedMessage
													id="table_cell_interest_amount_till_today"
													defaultMessage="Interest Amount till today"
												/></td>
												<td>{creditObj.interest_till_date}</td>
											</tr>
											</tbody>
										</Table>
									</Card.Body>
								</Accordion.Collapse>
							</Card>
						</Accordion>
					}) : ""}
				</Col>
			</Row>
		</Container>
	)
}

export default CreditHistory;
