import React, { useState, useContext, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  Table,
  Popover,
  OverlayTrigger,
  Button,
  Alert,
} from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import { getStorages } from "../../../services/Credit";
import { useHistory } from "react-router-dom";
import { Context } from "../../Layout/Default";

function MyCredit(props) {
  const context = useContext(Context);

  let history = useHistory();

  const [storages, setStorages] = useState([]);

  let farmer_id = context.getFarmerId();

  useEffect(() => {
    context.updateNavigationTitle("My Credit");

    async function fetchData() {
      try {
        const result = await getStorages(farmer_id);
        return result;
      } catch (err) {
        context.setShowError(true);
      }
    }

    fetchData().then((res) => setStorages(res.data));
  }, []);

  const popover = (
    <Popover id="popover-basic">
      <Popover.Title
        as="h5"
        onClick={(e) => {
          history.push("/my_credit/credit_faq");
        }}
      >
        <FormattedMessage
          id="info_click_to_know_more_about_credit"
          defaultMessage="Know more about credit facility"
        />
      </Popover.Title>
    </Popover>
  );

  const hasHistory = function (storages) {
    let hasHistory = false;

    if (storages.length) {
      storages.forEach(function (storage) {
        if (typeof storage.previous_credit_details.status != "undefined") {
          hasHistory = true;
          return;
        }
      });
    }

    return hasHistory;
  };

  console.log(hasHistory(storages));

  const storagesList = function (storages) {
    return storages.length
      ? storages.map((storage) => {
          return (
            <Card key={storage.storage_id}>
              <Card.Title className={"pt-2 text-center"}>
                <FormattedMessage id="rec_no" defaultMessage="Rec. No." />
                &nbsp;{storage.invoice}
              </Card.Title>
              <Card.Body>
                <Table bordered>
                  <tbody>
                    <tr>
                      <td>
                        <FormattedMessage id="crop" defaultMessage="Crop" />
                      </td>
                      <td>
                        <FormattedMessage
                          id={context.formatSlug(storage.crop)}
                          defaultMessage={storage.crop}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <FormattedMessage
                          id="table_cell_credit_limit"
                          defaultMessage="Credit Limit"
                        />
                      </td>
                      <td>{storage.credit_limit}</td>
                    </tr>
                    <tr>
                      <td>
                        <FormattedMessage
                          id="table_cell_credit_availed"
                          defaultMessage="Credit Availed"
                        />
                      </td>
                      <td>{storage.credit_used}</td>
                    </tr>
                    <tr>
                      <td>
                        <FormattedMessage
                          id="table_cell_available_credit"
                          defaultMessage="Available Credit"
                        />
                      </td>
                      <td>{storage.credit_available}</td>
                    </tr>
                  </tbody>
                </Table>
                <Button
                  variant="info"
                  block
                  onClick={(e) => {
                    localStorage.setItem(
                      "credit_storage_invoice",
                      storage.invoice
                    );
                    localStorage.setItem(
                      "credit_storage_id",
                      storage.storage_id
                    );
                    history.push({
                      pathname: "my_credit/request/",
                      storage_id: storage.storage_id,
                      crop_name: storage.crop,
                      storage_invoice: storage.invoice,
                    });
                  }}
                  disabled={
                    (parseInt(storage.credit_available) == 0 ? true : false) ||
                    storage.is_procured ||
                    storage.is_previous_credit
                  }
                >
                  <FormattedMessage
                    id="button_credit_request"
                    defaultMessage="New Credit Request"
                  />
                </Button>
                <Card.Footer
                  className={storage.is_procured ? "d-block" : "d-none"}
                >
                  <FormattedMessage
                    id="warning_credit_can_not_procured_being_processed"
                    defaultMessage="Credit request cannot process as this storage is being procured"
                  />
                  (<FormattedMessage id="pro_no" defaultMessage="Pro.No." />
                  &nbsp; {storage.procurement_details.procurement_invoice})
                </Card.Footer>
                <Card.Footer
                  className={storage.is_previous_credit ? "d-block" : "d-none"}
                >
                  <FormattedMessage
                    id={"credit_exists"}
                    defaultMessage={
                      "Existing Credit Request is in process. New request can be placed once it completes."
                    }
                  />
                </Card.Footer>
              </Card.Body>
            </Card>
          );
        })
      : null;
  };

  return (
    <Container fluid className="my-3">
      <Row>
        <Col className={"faq-wrapper mb-3"}>
          <OverlayTrigger
            trigger="click"
            placement="left"
            overlay={popover}
            defaultShow={true}
          >
            <Button variant="success">?</Button>
          </OverlayTrigger>
        </Col>
      </Row>
      {storages.length ? (
        <Row>
          <Col className="col-12">{storagesList(storages)}</Col>
          {hasHistory(storages) ? (
            <Col className="col-12 mt-3">
              <Button
                variant="info"
                block
                onClick={(e) => {
                  props.history.push("/my_credit/history");
                }}
              >
                <FormattedMessage
                  id="header_credit_history"
                  defaultMessage="Credit History"
                />
              </Button>
            </Col>
          ) : (
            ""
          )}
        </Row>
      ) : (
        <Row>
          <Col>
            <Alert variant="info">
              <FormattedMessage
                id="no_credit_available_as_no_storage"
                defaultMessage="Credit can be availed on produce stored at GramHeet.You have not stored any produce with us yet. To store your produce, please visit the nearest GramHeet Mandi or call 8985591751"
              />
            </Alert>
          </Col>
        </Row>
      )}
    </Container>
  );
}

export default MyCredit;
