import React, { useEffect, useContext } from "react";
import {
  Container,
  Row,
  Table,
  Col,
  Accordion,
  Card,
  Button,
} from "react-bootstrap";
import { FormattedMessage, useIntl } from "react-intl";
import { Context } from "../../Layout/Default";

function LabFaq(props) {
  const context = useContext(Context);
  const intl = useIntl();

  const marathiCrops = [
    "soyabean",
    "toor",
    "bengal_gram",
    "maize",
    "groundnut",
  ];
  const englishCrops = [
    "soyabean",
    "toor",
    "bengal_gram",
    "maize",
    "groundnut",
  ];
  const teluguCrops = englishCrops;

  const getCropBlock = (cropIntlName) => {
    return (
      <>
        <p>
          {" "}
          <FormattedMessage
            id={`lab_faq_question_1_answer.${cropIntlName}`}
          />{" "}
          :{" "}
        </p>
        <p>
          <FormattedMessage
            id={`lab_faq_question_1_answer.${cropIntlName}_standard`}
          />
        </p>

        {/* {cropIntlName === "groundnut" && groundNutTableBlock(intl.locale)} */}

        {cropIntlName !== "groundnut" && (
          <Table bordered>
            <thead>
              <tr>
                <th>
                  <FormattedMessage
                    id={`lab_faq_question_1_answer.${cropIntlName}_table_header_c1`}
                  />
                </th>
                <th>
                  <FormattedMessage
                    id={`lab_faq_question_1_answer.${cropIntlName}_table_header_c2`}
                  />
                </th>
                <th>
                  <FormattedMessage
                    id={`lab_faq_question_1_answer.${cropIntlName}_table_header_c3`}
                  />
                </th>
                <th>
                  <FormattedMessage
                    id={`lab_faq_question_1_answer.${cropIntlName}_table_header_c4`}
                  />
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <FormattedMessage
                    id={`lab_faq_question_1_answer.${cropIntlName}_table_row1_c1`}
                  />
                </td>
                <td>
                  <FormattedMessage
                    id={`lab_faq_question_1_answer.${cropIntlName}_table_row1_c2`}
                  />
                </td>
                <td>
                  <FormattedMessage
                    id={`lab_faq_question_1_answer.${cropIntlName}_table_row1_c3`}
                  />
                </td>
                <td>
                  <FormattedMessage
                    id={`lab_faq_question_1_answer.${cropIntlName}_table_row1_c4`}
                  />
                </td>
              </tr>
            </tbody>
          </Table>
        )}
      </>
    );
  };

  const groundNutTableBlock = (locale) => {
    const englishBlock = (
      <Table bordered size="sm">
        <thead>
          <tr>
            <th>Pods (in gram)</th>
            <th>100</th>
            <th>A</th>
            <th>B</th>
            <th>C</th>
            <th>D</th>
            <th>E</th>
          </tr>
          <tr>
            <th>Total Pods Qty (QTL)</th>
            <th>1</th>
            <th rowSpan={2}>Weight in Gram (sample)</th>
            <th rowSpan={2}>Percent %</th>
            <th rowSpan={2}>Quantity ( QTL) (total produce)</th>
            <th rowSpan={2}>Offered Price to farmers (Per QTL)</th>
            <th rowSpan={2}>Amount In Rs.</th>
          </tr>
          <tr>
            <th>Seeds outurn %</th>
            <th>72</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td colSpan={2}>50-60</td>
            <td style={{ color: "#0171c0", fontWeight: 800 }}>10</td>
            <td>10</td>
            <td>0.1</td>
            <td style={{ color: "#06b050", fontWeight: 800 }}>11500</td>
            <td>1150</td>
          </tr>
          <tr>
            <td colSpan={2}>60-70</td>
            <td style={{ color: "#0171c0", fontWeight: 800 }}>30</td>
            <td>30</td>
            <td>0.3</td>
            <td style={{ color: "#06b050", fontWeight: 800 }}>10500</td>
            <td>3150</td>
          </tr>
          <tr>
            <td colSpan={2}>70-80</td>
            <td style={{ color: "#0171c0", fontWeight: 800 }}>11</td>
            <td>11</td>
            <td>0.11</td>
            <td style={{ color: "#06b050", fontWeight: 800 }}>9500</td>
            <td>1045</td>
          </tr>
          <tr>
            <td colSpan={2}>80-90</td>
            <td style={{ color: "#0171c0", fontWeight: 800 }}>0</td>
            <td>0</td>
            <td>0</td>
            <td style={{ color: "#06b050", fontWeight: 800 }}>8500</td>
            <td>0</td>
          </tr>
          <tr>
            <td colSpan={2}>90-100</td>
            <td style={{ color: "#0171c0", fontWeight: 800 }}>0</td>
            <td>0</td>
            <td>0</td>
            <td style={{ color: "#06b050", fontWeight: 800 }}>6000</td>
            <td>0</td>
          </tr>
          <tr>
            <td colSpan={2}>50-60</td>
            <td style={{ color: "#0171c0", fontWeight: 800 }}>10</td>
            <td>10</td>
            <td>0.1</td>
            <td style={{ color: "#06b050", fontWeight: 800 }}>11500</td>
            <td>1150</td>
          </tr>
          <tr>
            <td colSpan={2}>140-160</td>
            <td style={{ color: "#0171c0", fontWeight: 800 }}>21</td>
            <td>21</td>
            <td>0.21</td>
            <td style={{ color: "#06b050", fontWeight: 800 }}>6000</td>
            <td>1260</td>
          </tr>
          <tr>
            <td colSpan={2}>C) Brokens or Civil</td>
            <td style={{ color: "#0171c0", fontWeight: 800 }}>0</td>
            <td>0</td>
            <td>0</td>
            <td style={{ color: "#06b050", fontWeight: 800 }}>2700</td>
            <td>0</td>
          </tr>
          <tr>
            <td colSpan={2}>2.Shells or Husk</td>
            <td style={{ color: "#0171c0", fontWeight: 800 }}>26</td>
            <td>26</td>
            <td>0.26</td>
            <td style={{ color: "#06b050", fontWeight: 800 }}>0</td>
            <td>0</td>
          </tr>
          <tr>
            <td colSpan={2}>3.Wastage</td>
            <td style={{ color: "#0171c0", fontWeight: 800 }}>2</td>
            <td>2</td>
            <td>0.02</td>
            <td style={{ color: "#06b050", fontWeight: 800 }}>0</td>
            <td>0</td>
          </tr>
          <tr>
            <td colSpan={2}>Moisture ( Standard = 8%)</td>
            <td style={{ color: "#0171c0", fontWeight: 800 }}>9</td>
            <td>9</td>
            <td>-1</td>
            <td style={{ color: "#06b050", fontWeight: 800 }}>66</td>
            <td>-66</td>
          </tr>
          <tr>
            <td colSpan={2}>Total</td>
            <td>100</td>
            <td>100</td>
            <td>1</td>
            <td></td>
            <td
              style={{
                background: "#b7dee7",
                color: "#06b050",
                fontWeight: 800,
              }}
            >
              6539
            </td>
          </tr>
        </tbody>
      </Table>
    );
    switch (locale) {
      case "en-US":
      case "en-GB":
      case "en-IN": {
        return englishBlock;
      }
      case "te-IN": {
        return (
          <Table bordered size="sm">
            <thead>
              <tr>
                <th>వేరుశెనగ కాయ ( గ్రా.లలో)</th>
                <th>100</th>
                <th>A</th>
                <th>B</th>
                <th>C</th>
                <th>D</th>
                <th>E</th>
              </tr>
              <tr>
                <th>మొత్తం వేరుశెనగ కాయ పరిమాణం ( క్వింటాళ్ళలో)</th>
                <th>1</th>
                <th rowSpan={2}>గ్రాములలో బరువు (సాంపిల్) </th>
                <th rowSpan={2}> శాతం %</th>
                <th rowSpan={2}>పరిమాణం (క్వింటాళ్ళలో) (మొత్తం ఉత్పత్తి)</th>
                <th rowSpan={2}>రైతులకు ఆఫర్ చేసిన ధర (క్వింటాలుకు) </th>
                <th rowSpan={2}>మొత్తం సొమ్ము (రూ.లలో)</th>
              </tr>
              <tr>
                <th>పల్లీల శాతం %</th>
                <th>72</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td colSpan={2}>50-60</td>
                <td style={{ color: "#0171c0", fontWeight: 800 }}>10</td>
                <td>10</td>
                <td>0.1</td>
                <td style={{ color: "#06b050", fontWeight: 800 }}>11500</td>
                <td>1150</td>
              </tr>
              <tr>
                <td colSpan={2}>60-70</td>
                <td style={{ color: "#0171c0", fontWeight: 800 }}>30</td>
                <td>30</td>
                <td>0.3</td>
                <td style={{ color: "#06b050", fontWeight: 800 }}>10500</td>
                <td>3150</td>
              </tr>
              <tr>
                <td colSpan={2}>70-80</td>
                <td style={{ color: "#0171c0", fontWeight: 800 }}>11</td>
                <td>11</td>
                <td>0.11</td>
                <td style={{ color: "#06b050", fontWeight: 800 }}>9500</td>
                <td>1045</td>
              </tr>
              <tr>
                <td colSpan={2}>80-90</td>
                <td style={{ color: "#0171c0", fontWeight: 800 }}>0</td>
                <td>0</td>
                <td>0</td>
                <td style={{ color: "#06b050", fontWeight: 800 }}>8500</td>
                <td>0</td>
              </tr>
              <tr>
                <td colSpan={2}>90-100</td>
                <td style={{ color: "#0171c0", fontWeight: 800 }}>0</td>
                <td>0</td>
                <td>0</td>
                <td style={{ color: "#06b050", fontWeight: 800 }}>6000</td>
                <td>0</td>
              </tr>
              <tr>
                <td colSpan={2}>50-60</td>
                <td style={{ color: "#0171c0", fontWeight: 800 }}>10</td>
                <td>10</td>
                <td>0.1</td>
                <td style={{ color: "#06b050", fontWeight: 800 }}>11500</td>
                <td>1150</td>
              </tr>
              <tr>
                <td colSpan={2}>140-160</td>
                <td style={{ color: "#0171c0", fontWeight: 800 }}>21</td>
                <td>21</td>
                <td>0.21</td>
                <td style={{ color: "#06b050", fontWeight: 800 }}>6000</td>
                <td>1260</td>
              </tr>
              <tr>
                <td colSpan={2}>C) విరిగినవి లేదా సివిల్ </td>
                <td style={{ color: "#0171c0", fontWeight: 800 }}>0</td>
                <td>0</td>
                <td>0</td>
                <td style={{ color: "#06b050", fontWeight: 800 }}>2700</td>
                <td>0</td>
              </tr>
              <tr>
                <td colSpan={2}>2. పెంకు / పొట్టు </td>
                <td style={{ color: "#0171c0", fontWeight: 800 }}>26</td>
                <td>26</td>
                <td>0.26</td>
                <td style={{ color: "#06b050", fontWeight: 800 }}>0</td>
                <td>0</td>
              </tr>
              <tr>
                <td colSpan={2}>3. వ్యర్థం </td>
                <td style={{ color: "#0171c0", fontWeight: 800 }}>2</td>
                <td>2</td>
                <td>0.02</td>
                <td style={{ color: "#06b050", fontWeight: 800 }}>0</td>
                <td>0</td>
              </tr>
              <tr>
                <td colSpan={2}> తేమ ( ప్రామాణికం = 8%)</td>
                <td style={{ color: "#0171c0", fontWeight: 800 }}>9</td>
                <td>9</td>
                <td>-1</td>
                <td style={{ color: "#06b050", fontWeight: 800 }}>66</td>
                <td>-66</td>
              </tr>
              <tr>
                <td colSpan={2}>Total</td>
                <td>100</td>
                <td>100</td>
                <td>1</td>
                <td></td>
                <td
                  style={{
                    background: "#b7dee7",
                    color: "#06b050",
                    fontWeight: 800,
                  }}
                >
                  6539
                </td>
              </tr>
            </tbody>
          </Table>
        );
      }
      default:
        return englishBlock;
    }
  };

  useEffect(() => {
    context.updateNavigationTitle("Quality Lab FAQ");
  }, []);

  return (
    <Container className={"faq-container my-3"}>
      <Row>
        <Col>
          <h4>
            <FormattedMessage
              id="lab_faq_info_1.header"
              defaultMessage="Information on quality testing"
            />
          </h4>
        </Col>
      </Row>

      <Row>
        <Col className={"mb-3 text-justify"}>
          <FormattedMessage
            id="lab_faq_info_1"
            defaultMessage="There is hardly any agriculture market in the country where procurement is done
			transparently based on the quality of produce. Farmers always get exploited under the
			current non-transparent bidding system. Unfortunately, farmers are not aware of the
			price determination process for their goods and because of the same, it is easy for the
			system to exploit the farmers. We are sure that such exploitation will stop if farmers are
			made aware of the price determination mechanism. GramMandi is obliged to make the
			selling process transparent and provide fair prices for agricultural produce based on its
			quality. Farmers are also convinced that the selling process will be more transparent if
			the procurement of agricultural produce is based on scientific quality testing. It is the			
			forte of GramMandi when it comes to deciding prices of produce on scientific quality
			testing. To inform the farmers about the quality parameters and quality testing process
			for their benefit in selling the produce, we are providing further information."
          />
        </Col>
      </Row>

      <Row>
        <Col className={"text-justify"}>
          <Accordion defaultActiveKey="0">
            <Card>
              <Card.Header>
                <Accordion.Toggle
                  as={Button}
                  variant="link"
                  eventKey="0"
                  className={"p-0"}
                >
                  <FormattedMessage
                    id="lab_faq_question_1"
                    defaultMessage="What are the minimum quality parameters for storage of farm produce
                    under this scheme?"
                  />
                </Accordion.Toggle>
              </Card.Header>
              <Accordion.Collapse eventKey="0">
                <Card.Body>
                  <p>
                    <FormattedMessage
                      id="lab_faq_question_1_answer.info"
                      defaultMessage="Each produce has different quality parameters which are mentioned in below-given
                      table. Our representative will provide the details of minimum required quality of the
                      produce on regular basis."
                    />
                  </p>

                  {intl.locale === "mr-IN"
                    ? marathiCrops.map((cropIntlName) =>
                        getCropBlock(cropIntlName)
                      )
                    : englishCrops.map((cropIntlName) =>
                        getCropBlock(cropIntlName)
                      )}
                </Card.Body>
              </Accordion.Collapse>
            </Card>

            <Card>
              <Card.Header>
                <Accordion.Toggle
                  as={Button}
                  variant="link"
                  eventKey="1"
                  className={"p-0"}
                >
                  <FormattedMessage
                    id="lab_faq_question_2"
                    defaultMessage="How are quality standards used to determine the price of agricultural
					produce?"
                  />
                </Accordion.Toggle>
              </Card.Header>
              <Accordion.Collapse eventKey="1">
                <Card.Body>
                  <FormattedMessage
                    id="lab_faq_question_2_answer"
                    defaultMessage="The quality of the produce differs from farmer to farmer and the price of produce is
					decided only after considering the quality of produce. The price declared every day is
					given to only those produce that holds the minimum quality standards."
                  />
                </Card.Body>
              </Accordion.Collapse>
            </Card>

            <Card>
              <Card.Header>
                <Accordion.Toggle
                  as={Button}
                  variant="link"
                  eventKey="2"
                  className={"p-0"}
                >
                  <FormattedMessage
                    id="lab_faq_question_3"
                    defaultMessage="If the quality of the produce is better than the standard quality, does that
					produce get a bonus on price?"
                  />
                </Accordion.Toggle>
              </Card.Header>
              <Accordion.Collapse eventKey="2">
                <Card.Body>
                  <FormattedMessage
                    id="lab_faq_question_3_answer"
                    defaultMessage="Yes. If the quality of produce is better than the standard quality then the bonus is
					offered to that produce. Better the quality higher is the bonus."
                  />
                </Card.Body>
              </Accordion.Collapse>
            </Card>

            <Card>
              <Card.Header>
                <Accordion.Toggle
                  as={Button}
                  variant="link"
                  eventKey="3"
                  className={"p-0"}
                >
                  <FormattedMessage
                    id="lab_faq_question_4"
                    defaultMessage="If the quality of the produce is poor than the standard quality, does that
					produce gets a lower price?"
                  />
                </Accordion.Toggle>
              </Card.Header>
              <Accordion.Collapse eventKey="3">
                <Card.Body>
                  <FormattedMessage
                    id="lab_faq_question_4_answer"
                    defaultMessage="Yes. If the quality of produce is poor than standard quality then the price gets reduced
					from the per quintal price of produce of that day. To know more about this section kindly
					reach out to the GramMandi helpline number."
                  />
                </Card.Body>
              </Accordion.Collapse>
            </Card>

            <Card>
              <Card.Header>
                <Accordion.Toggle
                  as={Button}
                  variant="link"
                  eventKey="4"
                  className={"p-0"}
                >
                  <FormattedMessage
                    id="lab_faq_question_5"
                    defaultMessage="When and how is quality testing done?"
                  />
                </Accordion.Toggle>
              </Card.Header>
              <Accordion.Collapse eventKey="4">
                <Card.Body>
                  <FormattedMessage
                    id="lab_faq_question_5_answer"
                    defaultMessage="When farmers take their produce to GramMandi's warehouse, they get detailed
					information about price determination based on quality testing. If the farmer agrees with
					the process of storage and sales, only then produce is unloaded from the vehicle. From
					each bag of produce an equal quantity of samples is taken. All the collected samples
					are used for quality testing, Process of quality testing for every produce is different as
					standards of quality are different for every produce. However, the process of sample
					collection is the same."
                  />
                </Card.Body>
              </Accordion.Collapse>
            </Card>

            <Card>
              <Card.Header>
                <Accordion.Toggle
                  as={Button}
                  variant="link"
                  eventKey="5"
                  className={"p-0"}
                >
                  <FormattedMessage
                    id="lab_faq_question_6"
                    defaultMessage="Can farmers bring a sample from their home for testing the quality of the
					produce?"
                  />
                </Accordion.Toggle>
              </Card.Header>
              <Accordion.Collapse eventKey="5">
                <Card.Body>
                  <FormattedMessage
                    id="lab_faq_question_6_answer"
                    defaultMessage="Yes, but this quality testing result is for farmers only to get a general idea of the quality
					of their produce. To get the precise result, farmers have to take an equal quantity of
					samples from each bag of their produce. Wrong results will be derived from the quality
					testing if samples are not taken in equal quantity. It should be noted that only the quality
					testing done at GramMandi center after the farmer comes with all the produce will be
					taken into consideration to decide prices although the farmer may have done the quality
					testing based on the sample taken by himself."
                  />
                </Card.Body>
              </Accordion.Collapse>
            </Card>
          </Accordion>
        </Col>
      </Row>
    </Container>
  );
}

export default LabFaq;
