import React, { useState, useContext } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import { Col, Button } from "react-bootstrap";
import { FormattedMessage, useIntl } from "react-intl";
import { useHistory } from "react-router-dom";
import { Image, Form } from "react-bootstrap";
import { sendOtp } from "../../services/otp";
import { Context } from "../Layout/Default";

function Login(props) {
  const context = useContext(Context);

  let history = useHistory();
  let intl = useIntl();

  const [mobile, setMobile] = useState();
  const [validated, setValidated] = useState(false);

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      handleRegisterClick();
    }

    setValidated(true);
  };

  const handleRegisterClick = async () => {
    try {
      const otpResult = await sendOtp(
        "91" + mobile,
        "registration",
        intl.locale
      );

      console.log("verificationResult:");
      console.log(otpResult);

      if (otpResult.data.type === "success") {
        history.push({
          pathname: "/verify",
          from: "login",
          mobile: mobile,
        });
      }
    } catch (err) {
      context.setShowError(true);
    }
  };

  return (
    <Container className={"registration-header"}>
      <Row className="my-5" style={{ textAlign: "center" }}>
        <Col>
          <Image src="grammadi.png" fluid />
        </Col>
      </Row>

      <Row>
        <Col>
          <Form
            noValidate
            validated={validated}
            //onSubmit={handleSubmit}
          >
            <Form.Group controlId="formGroupMobile">
              <Form.Label>
                <FormattedMessage
                  id="input_mobile"
                  defaultMessage="Mobile No"
                />
              </Form.Label>
              <Form.Control
                required
                type="number"
                placeholder={intl.formatMessage({
                  id: "input_mobile",
                  value: "Mobile No",
                })}
                value={mobile}
                onChange={(e) => {
                  setMobile(e.target.value);
                }}
              />
            </Form.Group>
            <Form.Group
              controlId="formGroupMobile"
              style={{ textAlign: "center" }}
            >
              <Button
                size="block"
                variant="primary"
                onClick={handleSubmit}
                //  href='/dashboard'
              >
                <FormattedMessage id="button_submit" defaultMessage="submit" />
              </Button>
            </Form.Group>
          </Form>
        </Col>
      </Row>
    </Container>
  );
}

export default Login;
