import React, { useState, useContext, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Form,
  Button,
  Alert,
  Table,
} from "react-bootstrap";
import Calendar from "react-calendar";
import { getPriceByDate } from "../../../services/GramMandi";
import { FormattedMessage, FormattedNumber, useIntl } from "react-intl";
import moment from "moment";
import { Context } from "../../Layout/Default";

function PriceHistory(props) {
  const context = useContext(Context);
  let intl = useIntl();
  const currentLocale = intl.locale;

  const [data, setData] = useState([]);

  const [date, setDate] = useState(new Date());

  const [cropName] = useState(
    localStorage.getItem("gram_mandi_crop_name")
      ? localStorage.getItem("gram_mandi_crop_name")
      : props.location.crop_name
  );
  const [cropId] = useState(
    localStorage.getItem("gram_mandi_crop_id")
      ? localStorage.getItem("gram_mandi_crop_id")
      : props.location.crop_id
  );

  const [showCalander, setShowCalander] = useState(false);

  const formatDate = (d) => {
    let month = "" + (d.getMonth() + 1);
    let day = "" + d.getDate();
    let year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  };

  const getTranslatedCropName = (grade) =>
    currentLocale === "en-IN" ? grade.name : grade.marathi_name;

  const fetchPrices = async (e) => {
    try {
      //console.log("CROP ID::"+cropId);
      const result = await getPriceByDate(cropId, formatDate(e));
      let finalResult = [];

      if ("price_list" in result.data) {
        result.data.price_list.forEach((e) => {
          if (e.crop_id === parseInt(cropId)) {
            console.log(e.price);
            finalResult.push(e);
          }
        });

        return finalResult;
      }
    } catch (err) {
      context.setShowError(true);
    }
  };

  const updatePriceData = (data) => {
    setData(data);
  };

  useEffect(() => {
    setShowCalander(props.location.show_price_histroy);
  }, [props.location.show_price_histroy]);

  useEffect(() => {
    context.updateNavigationTitle("Price History");

    if (props.location.crop_id !== undefined) {
      localStorage.setItem("gram_mandi_crop_id", props.location.crop_id);
    }

    if (props.location.crop_name !== undefined) {
      localStorage.setItem("gram_mandi_crop_name", props.location.crop_name);
    }
    fetchPrices(date).then((e) => updatePriceData(e));
  }, [date]);

  const handleOnCalendarChange = (e) => setDate(e);

  // console.log(props.location.crop_name)
  console.log(props.location.grade_data);

  return showCalander ? (
    <Container fluid className="my-3">
      {cropName ? (
        <Row className="text-center">
          <Col>
            <h3>
              <FormattedMessage
                id={cropName.toLowerCase()}
                defaultMessage={cropName}
              />
            </h3>
          </Col>
        </Row>
      ) : (
        ""
      )}

      <Row>
        <Col>
          <Alert variant="warning">
            <FormattedMessage
              id="info_click_on_date_to_view_price"
              defaultMessage="Click on date to view price on that day"
            />
          </Alert>
        </Col>
      </Row>

      <Row style={{ margin: "0 auto" }}>
        <Calendar
          onChange={(e) => handleOnCalendarChange(e)}
          locale={context.locale}
          maxDate={new Date()}
          defaultActiveStartDate={new Date()}
        />
      </Row>

      <Row className="mt-2">
        <Col>
          <Form.Group
            controlId="formGroupHistory"
            style={{ textAlign: "center" }}
          >
            <Button size="block" variant="info" href="/my_mandi/trend">
              <FormattedMessage
                id="button_weekly_trend"
                defaultMessage="Weekly Trend"
              />
            </Button>
          </Form.Group>
        </Col>
      </Row>

      <Row className="mt-2 text-center">
        <Col>
          <h5>
            <FormattedMessage
              id="price_on_date"
              defaultMessage="Price on {date}"
              values={{
                date: moment(date).format("DD-MM-YYYY"),
              }}
            />
          </h5>
        </Col>
      </Row>

      <Row className="mt-2 text-center">
        <Col>
          {data.length ? (
            <h3>
              <FormattedNumber value={data[0].price} />
            </h3>
          ) : (
            <h3>
              <FormattedMessage
                id="price_unavailable"
                defaultMessage="Price not available"
              />
            </h3>
          )}
        </Col>
      </Row>
    </Container>
  ) : (
    <Container>
      <Table bordered striped className="mt-5">
        <thead>
          <tr>
            <th colSpan={2} className="text-center">
              <FormattedMessage
                id="price_on_date"
                defaultMessage="Price on {date}"
                values={{ date: moment(date).format("DD-MM-YYYY") }}
              />
            </th>
          </tr>
          <th>
            <FormattedMessage
              id="grade_table_header_grade"
              defaultMessage="Grade"
            />
          </th>
          <th>
            <FormattedMessage
              id="grade_table_header_price"
              defaultMessage="Price"
            />
          </th>
        </thead>
        <tbody>
          {props.location.grade_data.length > 0
            ? props.location.grade_data.map((grade) => {
                return (
                  <tr key={grade.id}>
                    <td>{getTranslatedCropName(grade)}</td>
                    <td>{grade?.grade_prices?.[0]?.price}</td>
                  </tr>
                );
              })
            : ""}
        </tbody>
      </Table>
    </Container>
  );
}

export default PriceHistory;
