import React from "react";
import ReactDOM from "react-dom";
import reportWebVitals from "./reportWebVitals";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-calendar/dist/Calendar.css";
import "./index.css";
import { BrowserRouter } from "react-router-dom";
import Routes from "./Routes";
import { withStore } from "react-context-hook";
import { Default } from "./components/Layout/Default";

function MainApp(props) {
  return (
    //<React.StrictMode>
    <BrowserRouter>
      <Default>
        <Routes />
      </Default>
    </BrowserRouter>
    //</React.StrictMode>
  );
}

const initialState = {
  locale: "mr-IN",
};

const MainStore = withStore(MainApp, initialState);

ReactDOM.render(<MainStore />, document.getElementById("root"));

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
