import React, { useEffect, useContext } from "react";
import {
  Container,
  Row,
  Table,
  Col,
  Accordion,
  Card,
  Button,
} from "react-bootstrap";
import { FormattedMessage, useIntl } from "react-intl";
import { Context } from "../../Layout/Default";

function StorageFaq(props) {
  const context = useContext(Context);
  const intl = useIntl();
  useEffect(() => {
    context.updateNavigationTitle("Storage FAQ");
  }, []);

  return (
    <Container className={"my-3 faq-container"}>
      {/* <Row className={!toggle ? 'd-block' : 'd-none'}> */}
      <Row>
        <Col>
          <h5 className={"text-justify"}>
            <FormattedMessage
              id="storage_faq_info_1"
              defaultMessage="GramMandi has devised a transparent and scientific system for storage of the farm
produce. This will ease the selling process and ultimately facilitate the farmer in getting
better prices. Please visit your nearest GramMandi to store and sell your agricultural
produce. To know more about the storage and selling process of the GramMandi, click
on the question given below."
            />
          </h5>

          {/* <h4 onClick={onHandleClick}>शेतमाल विक्री व तारण विषयक माहिती.</h4> */}
        </Col>
      </Row>

      {/* <Row className={toggle ? 'd-block' : 'd-none'}> */}
      <Row className={"text-justify"}>
        <Col>
          <Accordion defaultActiveKey="0">
            <Card>
              <Card.Header>
                <Accordion.Toggle as={Button} variant="link" eventKey="0">
                  <FormattedMessage
                    id="storage_faq_question_1"
                    defaultMessage="Which agricultural commodities can be stored at GramMandi?"
                  />
                </Accordion.Toggle>
              </Card.Header>
              <Accordion.Collapse eventKey="0">
                <Card.Body>
                  <FormattedMessage
                    id="storage_faq_question_1_answer"
                    defaultMessage="Currently, we store Groundnut (Palli), Redgram (Kandi), Maize (Mokka Jonna). We will
                    also include other agricultural produce in this system in near future. Information
                    regarding the same will be updated on our mobile app and WhatsApp group."
                  />
                </Card.Body>
              </Accordion.Collapse>
            </Card>

            <Card>
              <Card.Header>
                <Accordion.Toggle as={Button} variant="link" eventKey="1">
                  <FormattedMessage
                    id="storage_faq_question_2"
                    defaultMessage="In which warehouses are these commodities get stored?"
                  />
                </Accordion.Toggle>
              </Card.Header>
              <Accordion.Collapse eventKey="1">
                <Card.Body>
                  <FormattedMessage
                    id="storage_faq_question_2_answer"
                    defaultMessage="GramMandi and partner FPC have rented various government and private warehouses
                    at different locations in the district. To get more information about these warehouses,
                    kindly reach out to the GramMandi helpline number or you will get information on the
                    mobile app."
                  />
                </Card.Body>
              </Accordion.Collapse>
            </Card>

            <Card>
              <Card.Header>
                <Accordion.Toggle as={Button} variant="link" eventKey="2">
                  <FormattedMessage
                    id="storage_faq_question_3"
                    defaultMessage="What are the documents required to store agricultural produce?"
                  />
                </Accordion.Toggle>
              </Card.Header>
              <Accordion.Collapse eventKey="2">
                <Card.Body>
                  <FormattedMessage
                    id="storage_faq_question_3_answer"
                    defaultMessage="Farmers must bring their Aadhar Card and Bank Passbook to store or to directly sell
                    their farm produce."
                  />
                </Card.Body>
              </Accordion.Collapse>
            </Card>

            <Card>
              <Card.Header>
                <Accordion.Toggle as={Button} variant="link" eventKey="3">
                  <FormattedMessage
                    id="storage_faq_question_4"
                    defaultMessage="How is the rent of stored agricultural produce charged?"
                  />
                </Accordion.Toggle>
              </Card.Header>
              <Accordion.Collapse eventKey="3">
                <Card.Body>
                  <FormattedMessage
                    id="storage_faq_question_4_answer"
                    defaultMessage="Once the farmer stores their produce, they don't have to pay the rent for the initial seven
                    days. But after seven days, a minimal charge of Rs. 12 /quintal/month will be incurred."
                  />
                </Card.Body>
              </Accordion.Collapse>
            </Card>

            <Card>
              <Card.Header>
                <Accordion.Toggle as={Button} variant="link" eventKey="4">
                  <FormattedMessage
                    id="storage_faq_question_5"
                    defaultMessage="What are the expenses that farmers have to bear during the storage and
                    sales of the produce?"
                  />
                </Accordion.Toggle>
              </Card.Header>
              <Accordion.Collapse eventKey="4">
                <Card.Body>
                  <FormattedMessage
                    id="storage_faq_question_5_answer"
                    defaultMessage="Farmers have to bring the produce to the warehouse at their own expense. No other
                    charges, in terms of Labour (Hamali), Brokerage (Dalali), Weighing, etc. are applied to
                    the farmers during storage and selling as well."
                  />
                </Card.Body>
              </Accordion.Collapse>
            </Card>

            <Card>
              <Card.Header>
                <Accordion.Toggle as={Button} variant="link" eventKey="5">
                  <FormattedMessage
                    id="storage_faq_question_6"
                    defaultMessage="Is it a necessity that an individual must be a farmer to store his/her
                    produce?"
                  />
                </Accordion.Toggle>
              </Card.Header>
              <Accordion.Collapse eventKey="5">
                <Card.Body>
                  <FormattedMessage
                    id="storage_faq_question_6_answer"
                    defaultMessage="Yes. The Individual storing the produce has to be a farmer. He/She may be a tenant
                    farmer or a farmer who owns the land."
                  />
                </Card.Body>
              </Accordion.Collapse>
            </Card>

            <Card>
              <Card.Header>
                <Accordion.Toggle as={Button} variant="link" eventKey="6">
                  <FormattedMessage
                    id="storage_faq_question_7"
                    defaultMessage="Can an individual farmer store the produce in the name of two different
                    farmers? Or can two different farmers store the produce in the name of one
                    farmer?"
                  />
                </Accordion.Toggle>
              </Card.Header>
              <Accordion.Collapse eventKey="6">
                <Card.Body>
                  <FormattedMessage
                    id="storage_faq_question_7_answer"
                    defaultMessage="Yes, but the storage receipt will be generated in the name of the single individual
                    farmer. Only the individual with the receipt in his/her name will have the right to sell the
                    produce or get the credit."
                  />
                </Card.Body>
              </Accordion.Collapse>
            </Card>

            <Card>
              <Card.Header>
                <Accordion.Toggle as={Button} variant="link" eventKey="7">
                  <FormattedMessage
                    id="storage_faq_question_8"
                    defaultMessage="Can a farmer take back his/her produce if he/she is not agreed with the
                    quality testing results?"
                  />
                </Accordion.Toggle>
              </Card.Header>
              <Accordion.Collapse eventKey="7">
                <Card.Body>
                  <FormattedMessage
                    id="storage_faq_question_8_answer"
                    defaultMessage="Yes, before storing the produce farmer can take his produce back. But once stored, a
                    farmer cannot take back his/her produce because it mixes with produce of other farmers
                    and gets held in a lot of 20-25 tons."
                  />
                </Card.Body>
              </Accordion.Collapse>
            </Card>

            <Card>
              <Card.Header>
                <Accordion.Toggle as={Button} variant="link" eventKey="8">
                  <FormattedMessage
                    id="storage_faq_question_9"
                    defaultMessage="What is the payment method for transactions done while taking credit or
                    selling the produce?"
                  />
                </Accordion.Toggle>
              </Card.Header>
              <Accordion.Collapse eventKey="8">
                <Card.Body>
                  <FormattedMessage
                    id="storage_faq_question_9_answer"
                    defaultMessage="The amount of credit or sales is directly transferred to the bank account of the farmer.
                    On-demand, payment is also made by ‘cheque’ on the same day. But we strictly avoid
                    payments in cash."
                  />
                </Card.Body>
              </Accordion.Collapse>
            </Card>

            <Card>
              <Card.Header>
                <Accordion.Toggle as={Button} variant="link" eventKey="9">
                  <FormattedMessage
                    id="storage_faq_question_10"
                    defaultMessage="Can farmers sell their produce in phases/parts?"
                  />
                </Accordion.Toggle>
              </Card.Header>
              <Accordion.Collapse eventKey="9">
                <Card.Body>
                  <FormattedMessage
                    id="storage_faq_question_10_answer"
                    defaultMessage="No. Farmers have to sell their stored produce in bulk at one go."
                  />
                </Card.Body>
              </Accordion.Collapse>
            </Card>

            <Card>
              <Card.Header>
                <Accordion.Toggle as={Button} variant="link" eventKey="10">
                  <FormattedMessage
                    id="storage_faq_question_11"
                    defaultMessage="What is the process of selling the stored produce at GramMandi?"
                  />
                </Accordion.Toggle>
              </Card.Header>
              <Accordion.Collapse eventKey="10">
                <Card.Body>
                  <FormattedMessage
                    id="storage_faq_question_11_answer"
                    defaultMessage="Everyday market prices of the produce are updated on the GramMandi app. Farmers
                    can sell their produce through the app on any day if they find the best price on that day.
                    Farmers are not required to come to the warehouse for the same. It is also to be kept in
                    mind that the farmers have to sell their produce before the date of validity of the storage
                    receipt."
                  />
                </Card.Body>
              </Accordion.Collapse>
            </Card>

            <Card>
              <Card.Header>
                <Accordion.Toggle as={Button} variant="link" eventKey="11">
                  <FormattedMessage
                    id="storage_faq_question_12"
                    defaultMessage="What happens if farmers do not sell their produce before given validity? Or
                    in how many days farmers are required to sell their stored produce?"
                  />
                </Accordion.Toggle>
              </Card.Header>
              <Accordion.Collapse eventKey="11">
                <Card.Body>
                  <FormattedMessage
                    id="storage_faq_question_12_answer.brief"
                    defaultMessage="The quality of agricultural produce can be maintained up to a certain period only as it
                    starts deteriorating after that period. Considering this fact, GramMandi has fixed the
                    storage validity of every agricultural produce. If farmers don’t sell their produce before
                    the given date of validity, GramMandi sells the produce on the very next day of the date
                    of validity. After selling the produce at the price of that day GramMandi calculates the
                    credit amount, interest on credit, and rent of the warehouse. This amount is deducted
                    from the total receivable amount and the remaining amount is directly transferred to the
                    farmer's bank account. Given below is the storage validity for different agricultural
                    produce"
                  />
                  <Table bordered>
                    <thead>
                      <tr>
                        <th>
                          <FormattedMessage
                            id="storage_faq_question_12_answer.table_column_1"
                            defaultMessage="Agriculture Produce"
                          />
                        </th>
                        <th>
                          <FormattedMessage
                            id="storage_faq_question_12_answer.table_column_2"
                            defaultMessage="Storage validity of the produce"
                          />
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <FormattedMessage id="storage_faq_question_12_answer.row_1_c1" />
                        </td>
                        <td>
                          <FormattedMessage id="storage_faq_question_12_answer.row_1_c2" />
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <FormattedMessage id="storage_faq_question_12_answer.row_2_c1" />
                        </td>
                        <td>
                          <FormattedMessage id="storage_faq_question_12_answer.row_2_c2" />
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <FormattedMessage id="storage_faq_question_12_answer.row_3_c1" />
                        </td>
                        <td>
                          <FormattedMessage id="storage_faq_question_12_answer.row_3_c2" />
                        </td>
                      </tr>
                      {intl.locale === "mr-IN" && (
                        <tr>
                          <td>
                            <FormattedMessage id="storage_faq_question_12_answer.row_4_c1" />
                          </td>
                          <td>
                            <FormattedMessage id="storage_faq_question_12_answer.row_4_c2" />
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </Table>
                  <span>
                    <FormattedMessage id="storage_faq_question_12_answer.footer" />
                  </span>
                </Card.Body>
              </Accordion.Collapse>
            </Card>

            <Card>
              <Card.Header>
                <Accordion.Toggle as={Button} variant="link" eventKey="12">
                  <FormattedMessage
                    id="storage_faq_question_13"
                    defaultMessage="How the everyday declared prices are decided by GramMandi?"
                  />
                </Accordion.Toggle>
              </Card.Header>
              <Accordion.Collapse eventKey="12">
                <Card.Body>
                  <FormattedMessage
                    id="storage_faq_question_13_answer"
                    defaultMessage="GramMandi directly sells the produce to processors, large traders, wholesalers, and
                    exporters. Everyday GramMandi takes the prices from these buyers and after deducting
                    the occurring expenses in the storage and selling process, the price of the day is
                    decided."
                  />
                </Card.Body>
              </Accordion.Collapse>
            </Card>

            <Card>
              <Card.Header>
                <Accordion.Toggle as={Button} variant="link" eventKey="13">
                  <FormattedMessage
                    id="storage_faq_question_14"
                    defaultMessage="The price of which day will be considered while selling the stored produce?"
                  />
                </Accordion.Toggle>
              </Card.Header>
              <Accordion.Collapse eventKey="13">
                <Card.Body>
                  <FormattedMessage
                    id="storage_faq_question_14_answer"
                    defaultMessage="Once the farmer stores their produce, they receive prices daily on WhatsApp or a
                    mobile App. The day farmer wants to sell their produce, the price of the same day will
                    be considered."
                  />
                </Card.Body>
              </Accordion.Collapse>
            </Card>

            <Card>
              <Card.Header>
                <Accordion.Toggle as={Button} variant="link" eventKey="14">
                  <FormattedMessage
                    id="storage_faq_question_15"
                    defaultMessage="Who will be held responsible in case of damage or theft of the produce?"
                  />
                </Accordion.Toggle>
              </Card.Header>
              <Accordion.Collapse eventKey="14">
                <Card.Body>
                  <FormattedMessage
                    id="storage_faq_question_15_answer"
                    defaultMessage="All the warehouses of GramMandi are covered under the insurance scheme. In case the
                    produce is damaged or stolen, GramMandi will take responsibility and full compensation
                    will be given to the farmers."
                  />
                </Card.Body>
              </Accordion.Collapse>
            </Card>

            <Card>
              <Card.Header>
                <Accordion.Toggle as={Button} variant="link" eventKey="15">
                  <FormattedMessage
                    id="storage_faq_question_16"
                    defaultMessage="Does the loss in weight of the produce between the period of storage and
                    sales get deducted at the time of sales? If yes, what is the method of
                    calculation?"
                  />
                </Accordion.Toggle>
              </Card.Header>
              <Accordion.Collapse eventKey="15">
                <Card.Body>
                  <FormattedMessage
                    id="storage_faq_question_16_answer"
                    defaultMessage="No. Moisture percentage is one of the parameters which is calculated in every produce.
                    At the time of storage, if the moisture is more than the standard level only then the
                    weight is deducted from the total weight of the produce accordingly"
                  />
                </Card.Body>
              </Accordion.Collapse>
            </Card>

            <Card>
              <Card.Header>
                <Accordion.Toggle as={Button} variant="link" eventKey="16">
                  <FormattedMessage
                    id="storage_faq_question_17"
                    defaultMessage="Can farmer sell their produce to GramMandi without storing it?"
                  />
                </Accordion.Toggle>
              </Card.Header>
              <Accordion.Collapse eventKey="16">
                <Card.Body>
                  <FormattedMessage
                    id="storage_faq_question_17_answer"
                    defaultMessage="Yes, farmers can directly sell their produce to GramMandi. Also, farmers can sell their
                    stored produce on the next day of storage."
                  />
                </Card.Body>
              </Accordion.Collapse>
            </Card>

            <Card>
              <Card.Header>
                <Accordion.Toggle as={Button} variant="link" eventKey="17">
                  <FormattedMessage
                    id="storage_faq_question_18"
                    defaultMessage="In case of any query/complaint, where should the farmers contact?"
                  />
                </Accordion.Toggle>
              </Card.Header>
              <Accordion.Collapse eventKey="17">
                <Card.Body>
                  <FormattedMessage
                    id="storage_faq_question_18_answer"
                    defaultMessage="Farmers can register their complaints by contacting on GramMandi's helpline number.
                    The query/complaint gets resolved within 48 hours."
                  />
                </Card.Body>
              </Accordion.Collapse>
            </Card>

            <Card>
              <Card.Header>
                <Accordion.Toggle as={Button} variant="link" eventKey="18">
                  <FormattedMessage
                    id="storage_faq_question_19"
                    defaultMessage="Do the farmers get a receipt for the storage of their produce?"
                  />
                </Accordion.Toggle>
              </Card.Header>
              <Accordion.Collapse eventKey="18">
                <Card.Body>
                  <FormattedMessage
                    id="storage_faq_question_19_answer"
                    defaultMessage="Yes. The receipt given contains all the necessary details of the produce stored. Farmers
                    can also avail of the e-receipt from the GramMandi mobile App. The hard copy of the
                    receipt is provided only once. Farmers can see the credit details and details of the sold
                    produce on the mobile App."
                  />
                </Card.Body>
              </Accordion.Collapse>
            </Card>
          </Accordion>
        </Col>
      </Row>
    </Container>
  );
}

export default StorageFaq;
