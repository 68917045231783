import axios from "./api";

export const registerAccount = (reqBody) => {
  return axios.post("register.json", reqBody);
};

export const getAccountDataBYMobile = (mobile) => {
  return axios.get(`farmer/${mobile}`);
};

export const getSellHistory = (farmerId) => {
  return axios.get(`sellHistory/${farmerId}`);
};

export const checkCanFarmerUpdateMobile = (farmerId, mobileNo) => {
  return axios.get(`canUpdate/${farmerId}/${mobileNo}`);
};

export const updateFarmer = (reqBody) => {
  return axios.put(`updateFarmer`, reqBody);
};

export const getStates = () => {
  return axios.post("getStates");
};

export const getDistricts = (state) => {
  return axios.post("getDistricts", {
    state,
  });
};

export const getBlocks = (district) => {
  return axios.post("getBlocks", {
    district,
  });
};

export const getFpos = (state, district, tehsil) => {
  return axios.post("getFpos", {
    state,
    district,
    tehsil,
  });
};
