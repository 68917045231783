import React, { useState, useEffect, useContext } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  Alert,
  Popover,
  OverlayTrigger,
  Button,
} from "react-bootstrap";
import { getStorageList } from "../../../services/MyGodown";
import { useHistory } from "react-router-dom";
import { Context } from "../../Layout/Default";
import { FormattedMessage, useIntl } from "react-intl";

function MyGodown(props) {
  const context = useContext(Context);
  let history = useHistory();
  const [storageList, setStorageList] = useState([]);

  let farmer_id = context.getFarmerId();

  const intl = useIntl();

  useEffect(async () => {
    context.updateNavigationTitle("My Godown");

    try {
      const result = await getStorageList(farmer_id);
      setStorageList(result.data);
    } catch (err) {
      context.setShowError(true);
    }
  }, [setStorageList]);

  const popover = (
    <Popover id="popover-basic">
      <Popover.Title
        as="h5"
        onClick={(e) => {
          history.push("/my_godown/storage_faq");
        }}
      >
        <FormattedMessage
          id="info_click_to_know_more_about_storage"
          defaultMessage="Know more about storage facility"
        />
      </Popover.Title>
    </Popover>
  );

  return (
    <Container className={"my-3"}>
      <Row>
        <Col className={"faq-wrapper mb-3"}>
          <OverlayTrigger
            trigger="click"
            placement="left"
            overlay={popover}
            defaultShow={true}
          >
            <Button variant="success">?</Button>
          </OverlayTrigger>
        </Col>
      </Row>

      {"storages" in storageList && storageList.storages.length ? (
        <Row>
          <Col>
            <Alert variant="warning">
              <FormattedMessage
                id="info_click_on_crop_name_to_view_details"
                defaultMessage="Click on crop name to view details"
              />
            </Alert>
          </Col>
        </Row>
      ) : (
        <Row>
          <Col>
            <Alert variant="info" className="text-center">
              <FormattedMessage
                id="info_no_crop_stored_or_all_storage_sold"
                defaultMessage="You have not stored any produce with GramHeet yet. To store your produce with us, please visit nearest GramHeet Mandi or call 8985591751"
              />
            </Alert>
          </Col>
        </Row>
      )}
      {"storages" in storageList && storageList.storages.length ? (
        <Row>
          {storageList.storages.map((storages) => {
            return (
              <Col
                className={"crop-item mt-3"}
                md={6}
                sm={12}
                key={storages.crop_name + "_" + storages.crop_id + "_key"}
              >
                <Card
                  className={"crop-card"}
                  bg="light"
                  style={{ borderRadius: "5px" }}
                  onClick={(e) => {
                    history.push({
                      pathname: `my_godown/storages/${farmer_id}/${storages.crop_id}/${storages.crop_name}`,
                      crop_id: props.corp_id,
                    });
                  }}
                >
                  <Card.Img
                    className={"crop-image"}
                    src={storageList.image_base_path + "/" + storages.image}
                  ></Card.Img>
                  <Card.Body>
                    <Card.Title className={"crop-name card-title-only"}>
                      {intl.formatMessage({
                        id: storages.crop_name.toLowerCase(),
                        defaultMessage: storages.crop_name,
                      })}
                    </Card.Title>
                  </Card.Body>
                </Card>
              </Col>
            );
          })}
        </Row>
      ) : (
        ""
      )}
    </Container>
  );
}

export default MyGodown;
