import React, { useState, useEffect, useContext } from "react";
import { Container, Row, Col, Alert } from "react-bootstrap";
import { getGramMandiRates } from "../../../services/GramMandi";
import Crop from "../../Common/Crop";
import { FormattedMessage } from "react-intl";
import moment from "moment";
import { Context } from "../../Layout/Default";

function GramMandi(props) {
  const context = useContext(Context);
  const [data, setData] = useState({});

  useEffect(() => {
    context.updateNavigationTitle("My Mandi");

    async function fetchData() {
      try {
        const result = await getGramMandiRates();
        return result;
      } catch (err) {
        context.setShowError(true);
      }
      return null;
    }

    fetchData().then((r) => setData(r.data));
  }, []);

  return (
    <Container className="mb-3">
      <Row>
        <Col className={"mt-3 text-center"}>
          <h4>
            <FormattedMessage
              id="header_todays_price"
              defaultMessage="Today’s Crop Prices (per QTL)"
            />
          </h4>
          <h5 className={"text-muted"}>{moment().format("DD-MM-YYYY")}</h5>
        </Col>
      </Row>

      <Row>
        <Col>
          <Alert variant="warning">
            <FormattedMessage
              id="info_click_to_view_produce"
              defaultMessage="Click on produce name to view recent prices."
            />
          </Alert>
        </Col>
      </Row>

      <Row sm={2}>
        {"crops" in data
          ? data.crops.map((crop, index) => {
              return (
                <Crop
                  key={index}
                  crop={crop}
                  image_base={data.image_base_path + "/"}
                />
              );
            })
          : ""}
      </Row>
    </Container>
  );
}

export default GramMandi;
