import axios from "./api";

export const getStorageList = (farmer_id) => {
	return axios.get(`storageList/${farmer_id}`);
};

export const getStorages = (farmer_id, crop_id) => {
	return axios.get(`storages/${farmer_id}/${crop_id}`);
};

export const getStorage = (storage_id) => {
	return axios.get(`getstorage/${storage_id}`);
};

export const createSellRequest = (storage_id) => {
	return axios.get(`sellRequest/${storage_id}`)
}

export const confirmSell = (storage_id) => {
	return axios.get(`sellConfirm/${storage_id}`)
}