import React, {useState, useEffect, useContext} from 'react';
import {Container, Row, Col, Form, Button} from 'react-bootstrap'
import {FormattedMessage} from 'react-intl'
import {Context} from "../../Layout/Default";
import {getAccountDataBYMobile} from "../../../services/Account";
import {sendOtp} from '../../../services/otp'


function EditAccount(props) {
	const context = useContext(Context);
	const [mobile, setMobile] = useState("");
	const [validated, setValidated] = useState(false);

	const handleSubmit = (event) => {
		const form = event.currentTarget;
		console.log(form.checkValidity())
		if (form.checkValidity() === false || mobile.length < 10) {
			event.preventDefault();
			event.stopPropagation();
		} else {
			handleUpdateClick()
		}

		setValidated(true);
	};


	const handleUpdateClick = async () => {
		try {
			const otpResult = await sendOtp("91" + mobile, "registration")

			console.log("verificationResult:");
			console.log(otpResult);

			if (otpResult.data.type === "success") {
				props.history.push({
					pathname: '/verify',
					from: 'account_update',
					mobile: mobile,
					old_mobile: context.getFarmerMobile(),
					farmer_id: context.getFarmerId()
				})
			}
		} catch (err) {
			context.setShowError(true)
		}
	}

	useEffect( () => {

		context.updateNavigationTitle('Change Mobile Number')

		async function fetchData() {
			console.log('Retrieving account info for ' + context.getFarmerMobile())
			const result = await getAccountDataBYMobile(context.getFarmerMobile())
			console.log('Result for account info ', result.data.success)

			return result
		}

		fetchData().then(res => {
			if (res.data.success) {
				setMobile(res.data.farmer_data.mobile)
			}
		})

	}, [])


	return (
		<Container className="my-3 edit-profile">
			<Row>
				<Col>
					<Form noValidate validated={validated}>
						<Form.Group controlId="formGroupMobile">
							<Form.Label><FormattedMessage
								id="enter_new_mobile_number"
								defaultMessage="Enter New Mobile No"
							/>.</Form.Label>
							<Form.Control required type="number" placeholder="Mobile No" value={mobile}
							              onChange={(e) => {
								              setMobile(e.target.value)
							              }}/>
						</Form.Group>
						<Form.Group controlId="formGroupMobile" style={{textAlign: 'center'}}>
							<Button size="block" variant="primary"
							        onClick={handleSubmit}
								//  href='/dashboard'
							><FormattedMessage
								id="button_submit"
								defaultMessage="submit"
							/></Button>
						</Form.Group>
					</Form>
				</Col>
			</Row>
		</Container>)
}


export default EditAccount;
