import axios from "./api";

export const sendOtp = (mobile, type, locale = "en-IN") => {
  return axios.get(`sendOtp/${mobile}/${type}/${locale}`);
};

export const verifyOtp = (otp, mobile) => {
  return axios.get(`verifyOtp/${mobile}/${otp}`);
};

export const resendOtp = (mobile) => {
  return axios.get(`resendOtp/${mobile}`);
};

// export const resendOtp = (otp,mobile) => {
//     axiosInstance.get('otp/retry',{ params: { authkey: '343391AycddXmw5f76d37fP1',mobile:mobile } });
// }
