import React, { useEffect, useState } from "react";
import { Row, Col, Navbar, Toast } from "react-bootstrap";
import { withRouter } from "react-router-dom";
import { FormattedMessage, IntlProvider, useIntl } from "react-intl";

import English from "../../locale/en.json";
import Marathi from "../../locale/mr.json";
import Telugu from "../../locale/tl.json";
import socketIOClient from "socket.io-client";
import PriceAlert from "../Common/PriceAlert";

export const Context = React.createContext();

export const Wrapper = (props) => {
  let localeVersion = "1.5.2.3";
  let localeKey = "gramheet.locale";
  let localeVersionKey = localeKey + ".version";
  let localeMessagesKey = localeKey + ".messages";

  let unsafePages = ["/home", "/login", "/verify", "/registration", "/fpo"];
  const [farmerId, _setFarmerId] = useState(
    localStorage.getItem("gramheet.farmer_id")
      ? localStorage.getItem("gramheet.farmer_id")
      : null
  );
  const [farmerMobile, _setFarmerMobile] = useState(
    localStorage.getItem("gramheet.farmer_mobile")
      ? localStorage.getItem("gramheet.farmer_mobile")
      : null
  );
  const [errorMsgId, setErrorMsgId] = useState("something_went_wrong");
  const [errorMsg, setErrorMsg] = useState("Something went wrong!");
  const [showError, setShowError] = useState(false);
  const [priceAlertData, setPriceAlertData] = useState();
  const [showPriceAlert, setShowPriceAlert] = useState(false);

  //console.log(farmerId);
  let loggedIn = farmerId !== 0 && farmerId !== "" && farmerId != null;
  let secureVerification =
    props.location.pathname === "/verify" &&
    typeof props.location.from != "undefined";

  if (!secureVerification) {
    if (unsafePages.includes(props.location.pathname) && loggedIn) {
      props.history.push("/dashboard");
    } else if (!unsafePages.includes(props.location.pathname) && !loggedIn) {
      props.history.push("/home");
    }
  }
  useEffect(() => {
    const socket = socketIOClient("http://65.0.254.144:3000", {
      extraHeaders: {
        "Access-Control-Request-Private-Network": true,
      },
    });
    // socket.on("connect", function (socket) {
    //   console.log("connected: ", socket);
    // });

    socket.on("notification", (data) => {
      const fpoId = getFPOId();
      if (fpoId == data?.fpo_id) {
        setPriceAlertData(data);
        setShowPriceAlert(true);
        // const toastId = toast.custom(
        //   ,
        //   {
        //     style: {
        //       color: "#375d02",
        //     },
        //   }
        // );
        setTimeout(() => {
          setShowPriceAlert(false);
        }, 2000);
      }
    });

    return () => {};
  }, []);

  const localeMessages = {
    en_US: English,
    "en-US": English,
    en_GB: English,
    "en-GB": English,
    en_IN: English,
    "en-IN": English,
    mr_IN: Marathi,
    "mr-IN": Marathi,
    te_IN: Telugu,
    "te-IN": Telugu,
  };

  if (!localStorage.getItem(localeKey) && localStorage.getItem("locale")) {
    localStorage.setItem(localeKey, localStorage.getItem("locale"));
    localStorage.removeItem("locale");
  }

  const [locale, setLocale] = useState(
    localStorage.getItem(localeKey)
      ? localStorage.getItem(localeKey)
      : navigator.language
  );
  const [messages, setMessages] = useState(
    localStorage.getItem(localeVersionKey) &&
      localStorage.getItem(localeMessagesKey) &&
      localStorage.getItem(localeVersionKey) == localeVersion
      ? JSON.parse(localStorage.getItem(localeMessagesKey))
      : localeMessages[locale]
  );
  const [navigationTitle, setNavigationTitle] = useState("");
  const [navigationTitleId, setNavigationTitleId] = useState(null);
  const [navigationTitleValues, setNavigationTitleValues] = useState({});

  if (
    !localStorage.getItem(localeVersionKey) ||
    localStorage.getItem(localeVersionKey) != localeVersion
  ) {
    let targetLocaleMessages = localeMessages[locale];
    localStorage.setItem(
      localeMessagesKey,
      JSON.stringify(targetLocaleMessages)
    );
    localStorage.setItem(localeVersionKey, localeVersion);
  }

  const switchLocale = (language) => {
    let targetLocale = language.target.value;
    let targetLocaleMessages = localeMessages[targetLocale];
    localStorage.setItem(localeKey, targetLocale);
    localStorage.setItem(
      localeMessagesKey,
      JSON.stringify(targetLocaleMessages)
    );
    setLocale(targetLocale);
    setMessages(targetLocaleMessages);
  };

  const handleBackButton = () => {
    if (props.location.pathname !== "/dashboard") {
      props.history.goBack();
    }
  };

  const handleHomeButton = () => {
    if (props.location.pathname !== "/dashboard") {
      props.history.push("/dashboard");
    }
  };

  /*
	const customIntlError = (err) => {
		if (err.code === "MISSING_TRANSLATION") {
			console.warn("Missing translation", err.message);
			return;
		}
		throw err;
	}
	*/

  const getTitleId = () => {
    if (!navigationTitleId) {
      return navigationTitle.split(" ").join("_").toLocaleLowerCase();
    } else if (navigationTitleId == "IGNORE") {
      return null;
    }

    return navigationTitleId;
  };

  const selectTitleId = (title_id) => {
    setNavigationTitleId(title_id);
  };

  const selectTitle = (title) => {
    setNavigationTitle(title);
  };

  const selectTitleValues = (title_values) => {
    setNavigationTitleValues(title_values);
  };

  const updateNavigationTitle = (message, id, values) => {
    if (typeof id == "undefined")
      id = message.split(" ").join("_").toLocaleLowerCase();
    if (id == "IGNORE") id = null;
    if (typeof values == "undefined") values = {};

    setNavigationTitleId(id);
    setNavigationTitle(message);
    setNavigationTitleValues(values);
  };

  const getFarmerId = () => {
    return farmerId;
  };

  const getFPOId = () => {
    return localStorage.getItem("gramheet.fpo_id");
  };

  const setFarmerId = (id) => {
    localStorage.setItem("gramheet.farmer_id", id);
    _setFarmerId(id);
  };

  const getFarmerMobile = () => {
    return farmerMobile;
  };

  const setFarmerMobile = (mobile) => {
    localStorage.setItem("gramheet.farmer_mobile", mobile);
    _setFarmerMobile(mobile);
  };

  const errorAlert = (id, defaultMessage) => {
    return (
      <Toast
        onClose={() => setShowError(false)}
        show={showError}
        delay={7000}
        autohide
      >
        <Toast.Body>
          <FormattedMessage id={id} defaultMessage={defaultMessage} />
        </Toast.Body>
      </Toast>
    );
  };

  const formatWeight = (intl, qty) => {
    qty = parseFloat(qty);

    qty *= 100;

    let qtlFloat = qty / 100;
    let kgFloat = qty % 100;

    const qtl = parseInt(qtlFloat);
    const kg = parseInt(kgFloat);
    const gm = (kgFloat - parseInt(kg)) * 1000;

    return (
      intl.formatNumber(qtl) +
      " " +
      intl.formatMessage({
        id: "qtl",
        defaultMessage: "qtl",
      }) +
      ", " +
      intl.formatNumber(kg) +
      " " +
      intl.formatMessage({
        id: "kg",
        defaultMessage: "kg",
      }) +
      ", " +
      intl.formatNumber(gm) +
      " " +
      intl.formatMessage({ id: "gm", defaultMessage: "gm" })
    );
  };

  const formatDate = (intl, dateString, dateStyle = "short") => {
    let dateStringArray = dateString.split("-");
    let reverseDateStringArray = dateStringArray.reverse();

    let reverseDateString = reverseDateStringArray.join("-");

    return intl.formatDate(reverseDateString, { dateStyle: dateStyle });
  };

  const formatSlug = (text) => {
    return text.toLowerCase().replace(/\(|\)/gi, "").replace(/ /gi, "_");
  };

  let navigation = (
    <Context.Provider
      value={{
        locale,
        switchLocale,
        selectTitle,
        getFarmerId,
        setFarmerId,
        getFarmerMobile,
        setFarmerMobile,
        setErrorMsg,
        setErrorMsgId,
        setShowError,
      }}
    >
      <IntlProvider
        key={locale}
        locale={locale}
        messages={messages}
        onError={(e) => console.log(e)}
      >
        {props.children}
        <PriceAlert show={showPriceAlert} priceAlertData={priceAlertData} />
      </IntlProvider>
    </Context.Provider>
  );

  if (
    !(
      props.location.pathname === "/login" ||
      props.location.pathname === "/verify" ||
      props.location.pathname === "/dashboard" ||
      props.location.pathname === "/home" ||
      props.location.pathname === "/fpo" ||
      props.location.pathname === "/registration"
    )
  ) {
    navigation = (
      <Context.Provider
        value={{
          locale,
          switchLocale,
          selectTitleId,
          selectTitleValues,
          updateNavigationTitle,
          selectTitle,
          getFarmerId,
          setFarmerId,
          getFarmerMobile,
          setFarmerMobile,
          formatWeight,
          formatDate,
          formatSlug,
          setErrorMsg,
          setErrorMsgId,
          setShowError,
        }}
      >
        <IntlProvider
          key={locale}
          locale={locale}
          messages={messages}
          onError={(e) => console.log(e)}
        >
          {showPriceAlert && (
            <div className="price-alert">
              <span>
                Alert! <strong>{priceAlertData.crop}</strong> is now{" "}
                <strong>₹ {priceAlertData.price}/-</strong>
              </span>
            </div>
          )}
          <Navbar sticky="top">
            <div className={"nav-container"}>
              <Row className={"nav-content text-center"}>
                <Col className={"nav-wrapper"}>
                  <div
                    className={"nav-back"}
                    style={{ backgroundImage: 'url("/arrow_left-black.svg")' }}
                    onClick={handleBackButton}
                  />
                  <span className={"nav-title"}>
                    {navigationTitleId ? (
                      <FormattedMessage
                        id={navigationTitleId}
                        defaultMessage={navigationTitle}
                        values={navigationTitleValues}
                      />
                    ) : (
                      navigationTitle
                    )}
                  </span>
                  <div
                    className={"nav-home"}
                    style={{ backgroundImage: 'url("/dashboard-black.svg")' }}
                    onClick={handleHomeButton}
                  />
                </Col>
              </Row>
            </div>
          </Navbar>
          <Row className="text-center">{errorAlert(errorMsgId, errorMsg)}</Row>
          {props.children}
        </IntlProvider>
      </Context.Provider>
    );
  }

  return navigation;
};

export const GramHeet = withRouter(Wrapper);

export const Default = withRouter(Wrapper);
