import axios from "./api";

export const getStorages = (farmer_id) => {
  return axios.get(`mycredit/${farmer_id}`);
};

export const getCreditRquest = (storage_id) => {
  return axios.get(`requestCredit/${storage_id}`);
};

export const confirmCreditRequest = (reqBody) => {
  return axios.post("confirmCredit", reqBody);
};

export const getCreditHistory = (farmer_id) => {
  return axios.get(`creditHistory/${farmer_id}`);
};
