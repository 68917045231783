import React, { useState, useContext, useEffect } from "react";
import { Container, Row, Col, Form, Button, Image } from "react-bootstrap";
import { FormattedMessage, useIntl } from "react-intl";
import { useHistory } from "react-router-dom";
import {
  getBlocks,
  getDistricts,
  getFpos,
  getStates,
} from "../../services/Account";
import { Context } from "../Layout/Default";

const FPO = (props) => {
  const context = useContext(Context);
  const intl = useIntl();
  const [states, setStates] = useState();
  const [selectedState, setSelectedState] = useState();
  const [districts, setDistricts] = useState();
  const [selectedDistrict, setSelectedDistrict] = useState();
  const [blocks, setBlocks] = useState();
  const [selectedBlock, setSelectedBlock] = useState();
  const [fpo, setFpo] = useState();
  const [selectedFPO, setSelectedFPO] = useState();
  const history = useHistory();

  const getSelectLabel = () => {
    return intl.formatMessage({
      id: "select",
      defaultMessage: "Select",
    });
  };

  const getFormattedData = (data) =>
    Object.keys(data).map((key) => {
      return {
        id: key,
        value: data[key],
      };
    });

  useEffect(() => {
    async function fetchStates() {
      try {
        const result = await getStates();
        return result;
      } catch (err) {
        context.setShowError(true);
      }
      return null;
    }

    fetchStates().then((r) => setStates(getFormattedData(r.data.states)));
  }, []);

  useEffect(() => {
    async function fetchDistricts() {
      try {
        const result = await getDistricts(selectedState);
        return result;
      } catch (err) {
        context.setShowError(true);
      }
      return null;
    }
    if (selectedState)
      fetchDistricts().then((r) =>
        setDistricts(getFormattedData(r.data.districts))
      );
  }, [selectedState]);

  useEffect(() => {
    async function fetchBlocks() {
      try {
        const result = await getBlocks(selectedDistrict);
        return result;
      } catch (err) {
        context.setShowError(true);
      }
      return null;
    }
    if (selectedDistrict)
      fetchBlocks().then((r) => setBlocks(getFormattedData(r.data.blocks)));
  }, [selectedDistrict]);

  useEffect(() => {
    async function fetchFPO() {
      try {
        const result = await getFpos(
          selectedState,
          selectedDistrict,
          selectedBlock
        );
        return result;
      } catch (err) {
        context.setShowError(true);
      }
      return null;
    }

    if (selectedBlock)
      fetchFPO().then((r) => setFpo(getFormattedData(r.data.fpo)));
  }, [selectedBlock]);

  const handleOnNextClick = () => {
    localStorage.setItem("gramheet.fpo_id", selectedFPO);
    props.history.push({
      pathname: "/registration",
      mobile: props.location.mobile,
    });
  };

  return (
    <Container fluid className="mt-5">
      <Container className={"registration-header"}>
        <Row className="my-2" style={{ textAlign: "center" }}>
          <Col>
            <Image src="grammadi.png" fluid />
          </Col>
        </Row>
        <Row className="my-5" style={{ textAlign: "center" }}>
          <Col>
            <h3>
              <FormattedMessage id="header_fpo" defaultMessage="Choose FPO" />
            </h3>
          </Col>
        </Row>
      </Container>

      <Container className={"registration-form"}>
        <Row>
          <Col>
            <Form>
              <Form.Group controlId="fpoForm.states">
                <Form.Label>
                  <FormattedMessage id="input_state" defaultMessage="States" />
                </Form.Label>
                <Form.Control
                  as="select"
                  value={selectedState || "choose"}
                  onChange={(e) => setSelectedState(e.target.value)}
                >
                  <option value="choose" disabled>
                    {getSelectLabel()}
                  </option>
                  {states?.map((option) => (
                    <option value={option.id}>{option.value}</option>
                  ))}
                </Form.Control>
              </Form.Group>

              {selectedState && (
                <Form.Group controlId="fpoForm.districts">
                  <Form.Label>
                    <FormattedMessage
                      id="input_district"
                      defaultMessage="Districts"
                    />
                  </Form.Label>
                  <Form.Control
                    as="select"
                    value={selectedDistrict || "choose"}
                    onChange={(e) => setSelectedDistrict(e.target.value)}
                  >
                    <option value="choose" disabled>
                      {getSelectLabel()}
                    </option>
                    {districts?.map((option) => (
                      <option value={option.id}>{option.value}</option>
                    ))}
                  </Form.Control>
                </Form.Group>
              )}

              {selectedDistrict && (
                <Form.Group controlId="fpoForm.Blocks">
                  <Form.Label>
                    <FormattedMessage
                      id="input_block"
                      defaultMessage="Blocks"
                    />
                  </Form.Label>
                  <Form.Control
                    as="select"
                    value={selectedBlock || "choose"}
                    onChange={(e) => setSelectedBlock(e.target.value)}
                  >
                    <option value="choose" disabled>
                      {getSelectLabel()}
                    </option>
                    {blocks?.map((option) => (
                      <option value={option.id}>{option.value}</option>
                    ))}
                  </Form.Control>
                </Form.Group>
              )}

              {selectedBlock && (
                <Form.Group controlId="fpoForm.FPO">
                  <Form.Label>
                    <FormattedMessage id="input_fpo" defaultMessage="FPO" />
                  </Form.Label>
                  <Form.Control
                    as="select"
                    value={selectedFPO || "choose"}
                    onChange={(e) => setSelectedFPO(e.target.value)}
                  >
                    <option value="choose" disabled>
                      {getSelectLabel()}
                    </option>
                    {fpo?.map((option) => (
                      <option value={option.id}>{option.value}</option>
                    ))}
                  </Form.Control>
                </Form.Group>
              )}

              <Form.Group
                controlId="formGroupMobile"
                style={{ textAlign: "center" }}
              >
                <Button
                  size="block"
                  variant="primary"
                  onClick={handleOnNextClick}
                  disabled={!selectedFPO}
                  //  href='/dashboard'
                >
                  <FormattedMessage
                    id="button_fpo_next"
                    defaultMessage="Next"
                  />
                </Button>
              </Form.Group>
            </Form>
          </Col>
        </Row>
      </Container>
    </Container>
  );
};

export default FPO;
