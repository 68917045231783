import axios from "./api";

export const getGramMandiRates = () => {
  const framer_id = localStorage.getItem("gramheet.farmer_id");
  return axios.get(`crops/${framer_id}`);
};

export const getPriceByDate = (crop_id, date) => {
  return axios.get(`priceByDate/${crop_id}/${date}`);
};

export const getPriceByFromToDate = (crop_id, from, to) => {
  return axios.get(`priceByDate/${crop_id}/${from}/${to}`);
};
