import React, { useState, useContext } from "react";
import { Container, Row, Col, Form, Button, Image } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import { useHistory } from "react-router-dom";
import { registerAccount } from "../../services/Account";
import { Context } from "../Layout/Default";

function Register(props) {
  const context = useContext(Context);
  let history = useHistory();

  const [fname, setFname] = useState("");
  const [faname, setFaname] = useState("");
  const [lname, setlname] = useState("");
  const [mobile, setMobile] = useState(props.location.mobile);

  const handleRegisterClick = async () => {
    //console.log(result)
    try {
      const result = await registerAccount({
        first_name: fname,
        middle_name: faname,
        last_name: lname,
        name: `${fname} ${faname} ${lname}`,
        mobile: mobile,
        gender: 1,
        organisation_id: localStorage.getItem("gramheet.fpo_id"),
      });

      console.log("registrationResult:");
      console.log(result.data);

      if (result.data.success && result.data.type == "registered") {
        context.setFarmerId(result.data.farmer_data[0]["id"]);
        context.setFarmerMobile(result.data.farmer_data[0]["mobile"]);
      }

      if (result.data.success && result.data.type == "new") {
        context.setFarmerId(result.data.farmer_data.id);
        context.setFarmerMobile(result.data.farmer_data.mobile);
      }

      if (result.data.success) {
        history.push({
          pathname: "/dashboard",
          mobile: mobile,
          from: "registration",
        });
      }
    } catch (err) {
      context.setShowError(true);
    }
  };

  return (
    <Container fluid className="mt-5">
      <Container className={"registration-header"}>
        <Row className="my-2" style={{ textAlign: "center" }}>
          <Col>
            <Image src="grammadi.png" fluid />
          </Col>
        </Row>
        <Row className="my-5" style={{ textAlign: "center" }}>
          <Col>
            <h3>
              <FormattedMessage
                id="header_registration"
                defaultMessage="Register"
              />
            </h3>
          </Col>
        </Row>
      </Container>

      <Container className={"registration-form"}>
        <Row>
          <Col>
            <Form>
              <Form.Group controlId="formGroupFirstName">
                <Form.Label>
                  <FormattedMessage
                    id="input_first_name"
                    defaultMessage="First Name"
                  />
                </Form.Label>
                <Form.Control
                  type="text"
                  placeholder="First Name"
                  value={fname}
                  onChange={(e) => setFname(e.target.value)}
                />
              </Form.Group>
              <Form.Group controlId="formGroupFathersName">
                <Form.Label>
                  <FormattedMessage
                    id="input_fathers_name"
                    defaultMessage="Father's Name"
                  />
                </Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Father's name"
                  value={faname}
                  onChange={(e) => setFaname(e.target.value)}
                />
              </Form.Group>
              <Form.Group controlId="formGroupLastName">
                <Form.Label>
                  <FormattedMessage
                    id="input_last_name"
                    defaultMessage="Last Name"
                  />
                </Form.Label>
                <Form.Control
                  type="text"
                  placeholder="last name"
                  value={lname}
                  onChange={(e) => setlname(e.target.value)}
                />
              </Form.Group>
              <Form.Group controlId="formGroupMobile">
                <Form.Label>
                  <FormattedMessage
                    id="input_mobile"
                    defaultMessage="Mobile No"
                  />
                </Form.Label>
                <Form.Control
                  type="number"
                  disabled
                  placeholder="Mobile No"
                  value={mobile}
                  onChange={(e) => setMobile(e.target.value)}
                />
              </Form.Group>
              <Form.Group
                controlId="formGroupMobile"
                style={{ textAlign: "center" }}
              >
                <Button
                  size="block"
                  variant="primary"
                  onClick={handleRegisterClick}
                  //  href='/dashboard'
                >
                  <FormattedMessage id="button_next" defaultMessage="Next" />
                </Button>
              </Form.Group>
            </Form>
          </Col>
        </Row>
      </Container>
    </Container>
  );
}

export default Register;
