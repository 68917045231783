import React, {useState, useEffect, useContext} from 'react';
import {Container, Row, Col, Card, Accordion, Table, Alert, Button} from 'react-bootstrap'
import {getStorage} from '../../../services/MyGodown'
import {FormattedMessage, useIntl} from 'react-intl'
import {Context} from "../../Layout/Default";
import {MdPictureAsPdf} from "react-icons/md/index"
import {Link} from 'react-router-dom';

function StorageView(props) {
	const context = useContext(Context);
	const intl = useIntl();

	const [storage, setStorage] = useState([]);
	const [details, setDetails] = useState([]);
	const [sampleWeight, setSampleWeight] = useState([]);
	const [totalComodityRate, setTotalComodityRate] = useState([]);
	const [totalBad, setTotalBad] = useState([]);

	let storage_id = props.match.params.storage_id;

	useEffect( () => {

		async function fetchData() {
			try {
				const result = await getStorage(storage_id);

				return result
			} catch (err) {
				context.setShowError(true)
			}
		}

		fetchData().then(result => {
			setSampleWeight(result.data.quality_checks.sample_weight);
			setDetails(result.data.quality_checks.details);
			setStorage(result.data);

			context.updateNavigationTitle(result.data.invoice, 'IGNORE');


			let ComodityRateAr = result.data.quality_checks.details.map((dt) => {
				return dt.comodityRate
			});

			setTotalComodityRate(ComodityRateAr.reduce(function (a, b) {
				return a + b
			}));

			let TotalBadAr = result.data.quality_checks.details.map((dt) => {
				return dt.totalBad
			});
			setTotalBad(TotalBadAr.reduce(function (a, b) {
				return a + b
			}));
		})

	}, [setStorage]);


	const handleOnSellClick = () => {
		props.history.push({
			pathname: '/my_godown/sell_storage',
			storage_id: storage_id
		})
	}

	const handleOnCreditClick = () => {
		props.history.push({
			pathname: '/my_credit/request',
			storage_invoice: props.location.storage_invoice,
			crop_name: props.location.crop_name,
			storage_id: storage_id
		})
	}


	const procured_alert = () => {
		return storage.is_procured ? (
			<Row>
				<Col>
					<Alert variant='warning'>
						<FormattedMessage
							id="label_produce_is_being_sold"
							defaultMessage="This produce is sold"
						/>
					</Alert>
				</Col>
			</Row>
		) : "";
	}

	const hasPendingCreditRequest = function (storage) {
		let hasPendingCreditRequest = false;

		if (typeof storage.previous_credits != 'undefined' && storage.previous_credits.length) {
			storage.previous_credits.forEach(function (credit) {
				if (credit.status != 'disbursed') {
					hasPendingCreditRequest = true;
					return;
				}
			})
		}

		return hasPendingCreditRequest;
	}

	return (
		<Container fluid className="my-3">
			{procured_alert()}
			{/* {storage.pdf_url != "" && (
				<Row>
					<Col className={"download-wrapper text-right my-2"}>
						<Link download to={{pathname: storage.pdf_url}} target="_blank">
							<FormattedMessage
								id="download_receipt"
								defaultMessage="Download Receipt"
							/> <MdPictureAsPdf/></Link>
					</Col>
				</Row>
			)} */}
			<Row>
				<Col>
					<Table striped bordered hover>
						<tbody>
						<tr>
							<th>
								<FormattedMessage
									id="storage_quantity"
									description="my view stored page -> Qunatity lable"
									defaultMessage="Quantity"
								/>
							</th>
							<td>{context.formatWeight(intl, storage.quantity)}</td>
						</tr>
						<tr>
							<th>
								<FormattedMessage
									id="date_of_storage_label"
									defaultMessage="Date of storage"
								/>
							</th>
							<td>{storage.storage_date}</td>
						</tr>
						<tr>
							<th>
								<FormattedMessage
									id="validity_to_sell_label"
									defaultMessage="Validity to sell"
								/>
							</th>
							<td>{storage.validity}</td>
						</tr>
						<tr>
							<th>
								<FormattedMessage
									id="status"
									defaultMessage="Status"
								/>
							</th>
							<td>
								{storage.status == 'stored' ? (
									<FormattedMessage
										id="stored"
										defaultMessage="Stored"
									/>
								) : (
									<FormattedMessage
										id="status_payment_processing"
										defaultMessage="Payment is in progress"
									/>
								)}
							</td>
						</tr>
						</tbody>
					</Table>

					<Table bordered>
						<tbody>
						<tr>
							<th colSpan="2" className="text-center">
								<FormattedMessage
									id="quality_checks_label"
									defaultMessage="Quality Checks"
								/>
							</th>
						</tr>
						<tr>
							<th>
								<FormattedMessage
									id="sample_weight"
									defaultMessage="Sample Weight (in gm)"
								/>
							</th>
							<td>{sampleWeight}</td>
						</tr>
						</tbody>
					</Table>

					<Accordion defaultActiveKey="0">
						{details.map((detail, _i) => {
							let parameterSlug = context.formatSlug(detail.label);

							return (
								<Card key={detail.label}>
									<Accordion.Toggle as={Card.Header} eventKey={_i + ""} className="text-center">
										<FormattedMessage
											id={parameterSlug}
											defaultMessage={detail.label}
										/>
									</Accordion.Toggle>
									<Accordion.Collapse eventKey={_i + ""}>
										<Card.Body className={"section-" + parameterSlug}>
											<Table striped bordered hover>
												<tbody>
												{parameterSlug != "moisture" ?
													<tr>
														<th>
															<FormattedMessage
																id="Weight_gm_label"
																defaultMessage="Weight (GM)"
															/>
														</th>
														<td>{parseFloat(detail.value).toFixed(2)}</td>
													</tr>
													: null}
												<tr>
													<th>
														<FormattedMessage
															id="percentage_label"
															defaultMessage="Percentage"
														/>
													</th>
													<td>{parseFloat(detail.percentage).toFixed(2)}%</td>
												</tr>
												<tr>
													<th>
														<FormattedMessage
															id={parameterSlug}
															defaultMessage={detail.label}
														/> <FormattedMessage
															id="label_per_qtl"
															defaultMessage="Per QTL"
														/>
													</th>
													<td>{parseFloat(detail.comodityRate).toFixed(2)}</td>
												</tr>
												<tr>
													<th>
														<FormattedMessage
															id={parameterSlug}
															defaultMessage={detail.label}
														/> <FormattedMessage
															id="label_in_all"
															defaultMessage="In whole produce"
														/>

													</th>
													<td>{parseFloat(detail.totalBad).toFixed(2)}</td>
												</tr>
												</tbody>
											</Table>
										</Card.Body>
									</Accordion.Collapse>
								</Card>
							);
						})}
					</Accordion>
				</Col>


				<Col md={12} className={"my-3 font-weight-bold"}>
					<Table bordered>
						<tbody>
						<tr>
							<th>
								<FormattedMessage
									id="total_comodity_rate_label"
									description="my view stored page ->  Total Comodity Rate lable"
									defaultMessage="Total Comodity Rate"
								/>
							</th>
							<td>{parseFloat(totalComodityRate).toFixed(2)}</td>
						</tr>
						<tr>
							<th>
								<FormattedMessage
									id="total_bad_label"
									description="my view stored page ->  Total Bad lable"
									defaultMessage="Total Bad"
								/>
							</th>
							<td>{parseFloat(totalBad).toFixed(2)}</td>
						</tr>
						</tbody>
					</Table>
				</Col>
			</Row>

			<Row>
				<Col>
					<Button variant="outline-info" block
					        disabled={(storage.is_procured || hasPendingCreditRequest(storage)) ? true : false}
					        onClick={handleOnCreditClick}>
						<FormattedMessage
							id="credit_label"
							description="my view stored page ->  CREDIT lable"
							defaultMessage="CREDIT"
						/>
					</Button>
				</Col>
				<Col>

					<Button variant="outline-info" block className={(storage.is_procured) ? "d-none" : "d-block"}
					        onClick={handleOnSellClick}>
						<FormattedMessage
							id="sell_label"
							description="my view stored page ->  SELL lable"
							defaultMessage="SELL"
						/>
					</Button>

				</Col>
			</Row>
		</Container>)
}

export default StorageView;
