import React, { useState, useEffect, useContext } from "react";
import {
  Container,
  Row,
  Col,
  Toast,
  Form,
  Button,
  Alert,
} from "react-bootstrap";
import { FormattedMessage, useIntl } from "react-intl";
import {
  getAccountDataBYMobile,
  checkCanFarmerUpdateMobile,
} from "../../../services/Account";
import { Context } from "../../Layout/Default";

function MyAccount(props) {
  const context = useContext(Context);
  const intl = useIntl();

  const [myid, setMyId] = useState("");
  const [fname, setFname] = useState("");
  const [mname, setMname] = useState("");
  const [lname, setLname] = useState("");
  const [village, setVillage] = useState("");
  const [tehsil, setTehsil] = useState("");
  const [district, setDistrict] = useState("");
  const [mobile, setMobile] = useState("");
  const [pin, setPin] = useState("");
  const [adharno, setAdharno] = useState("");
  const [bankAccount, setBankAccount] = useState("");
  const [bankName, setBankName] = useState("");
  const [bankIFSC, setBankIFSC] = useState("");

  const [showError, setShowError] = useState(false);

  useEffect(() => {
    context.updateNavigationTitle(
      intl.formatMessage({
        id: "menu_my_account",
        defaultMessage: "My Account",
      })
    );

    console.log("Retrieving account info for " + context.getFarmerMobile());

    async function fetchData() {
      try {
        const result = await getAccountDataBYMobile(context.getFarmerMobile());

        return result;
      } catch (err) {
        context.setShowError(true);
      }
    }

    fetchData().then((result) => {
      console.log("Result for account info ", result.data.success);

      if (result.data.success) {
        console.log(result.data);
        setFname(result.data.farmer_data.first_name);
        setMname(result.data.farmer_data.middle_name);
        setLname(result.data.farmer_data.last_name);
        setVillage(result.data.farmer_data.village);
        setTehsil(result.data.farmer_data.tehsil);
        setDistrict(result.data.farmer_data.district);
        setMobile(result.data.farmer_data.mobile);
        setAdharno(result.data.farmer_data.uid);
        setPin(result.data.farmer_data.pin);
        setMyId(result.data.farmer_data.code);

        if (
          typeof result.data.farmer_data.farmer_banks != "undefined" &&
          result.data.farmer_data.farmer_banks.length
        ) {
          let defaultBank = result.data.farmer_data.farmer_banks[0];

          setBankAccount(defaultBank.account);
          setBankName(defaultBank.name);
          setBankIFSC(defaultBank.ifsc);
        }
      }
    });
  }, []);

  const handeChangeClick = async () => {
    const result = await checkCanFarmerUpdateMobile(
      context.getFarmerId(),
      context.getFarmerMobile()
    );

    if (result.data.success) {
      props.history.push("/my_account/edit");
    } else {
      setShowError(true);
    }
  };

  const onHandleLogout = () => {
    localStorage.clear();
    setTimeout(() => {
      window.location.reload();
    }, 1500);
  };

  return (
    <Container className="my-3 my-account">
      <Row>
        <Col>
          <Toast
            onClose={() => setShowError(false)}
            show={showError}
            delay={7000}
            autohide
          >
            <Toast.Body>
              <FormattedMessage
                id="error_can_not_change_mobile"
                defaultMessage="Mobile number does not belong to farmer"
              />
            </Toast.Body>
          </Toast>
        </Col>
      </Row>
      <Row>
        <Col>
          <Form>
            <Form.Group controlId="formGroupMyId">
              <Form.Label>
                <FormattedMessage
                  id="input_id_number"
                  defaultMessage="My Farmer ID"
                />
              </Form.Label>
              <Form.Label>{myid}</Form.Label>
            </Form.Group>
            <Form.Group controlId="formGroupFirstName">
              <Form.Label>
                <FormattedMessage
                  id="input_first_name"
                  defaultMessage="First Name"
                />
              </Form.Label>
              <Form.Label>{fname}</Form.Label>
            </Form.Group>
            <Form.Group controlId="formGroupMiddleName">
              <Form.Label>
                <FormattedMessage
                  id="input_fathers_name"
                  defaultMessage="Father’s Name"
                />
              </Form.Label>
              <Form.Label>{mname}</Form.Label>
            </Form.Group>
            <Form.Group controlId="formGroupLastName">
              <Form.Label>
                <FormattedMessage
                  id="input_last_name"
                  defaultMessage="Last Name"
                />
              </Form.Label>
              <Form.Label>{lname}</Form.Label>
            </Form.Group>
            <Form.Group controlId="formGroupVillage">
              <Form.Label>
                <FormattedMessage id="village" defaultMessage="Village" />
              </Form.Label>
              <Form.Label>{village}</Form.Label>
            </Form.Group>
            {tehsil != "" && Object.keys(tehsil).length && (
              <Form.Group controlId="formGroupTehsil">
                <Form.Label>
                  <FormattedMessage id="tehsil" defaultMessage="Block" />
                </Form.Label>
                <Form.Label>{tehsil.name}</Form.Label>
              </Form.Group>
            )}
            {district != "" && Object.keys(district).length && (
              <Form.Group controlId="formGroupDistrict">
                <Form.Label>
                  <FormattedMessage id="district" defaultMessage="District" />
                </Form.Label>
                <Form.Label>{district.name}</Form.Label>
              </Form.Group>
            )}
            <Form.Group controlId="formGroupPinCode">
              <Form.Label>
                <FormattedMessage
                  id="input_pincode"
                  defaultMessage="Pin Code"
                />
              </Form.Label>
              <Form.Label>{pin}</Form.Label>
            </Form.Group>
            <Form.Group controlId="formGroupMobile">
              <Form.Label>
                <FormattedMessage
                  id="input_mobile_number"
                  defaultMessage="Mobile No"
                />
              </Form.Label>
              <Form.Label>{mobile}</Form.Label>
            </Form.Group>
            <Form.Group controlId="formGroupAdharCard">
              <Form.Label>
                <FormattedMessage
                  id="input_aadhar_card"
                  defaultMessage="Aadhar Card No"
                />
              </Form.Label>
              <Form.Label>{adharno}</Form.Label>
            </Form.Group>
            <Form.Group controlId="formGroupBankAcc">
              <Form.Label>
                <FormattedMessage
                  id="input_bank_account_number"
                  defaultMessage="Bank A/C No."
                />
              </Form.Label>
              <Form.Label>{bankAccount}</Form.Label>
            </Form.Group>
            <Form.Group controlId="formGroupBankName">
              <Form.Label>
                <FormattedMessage
                  id="input_bank_name"
                  defaultMessage="Bank Name"
                />
              </Form.Label>
              <Form.Label>{bankName}</Form.Label>
            </Form.Group>
            <Form.Group controlId="formGroupIFSC">
              <Form.Label>
                <FormattedMessage id="input_ifsc" defaultMessage="IFSC" />
              </Form.Label>
              <Form.Label>{bankIFSC}</Form.Label>
            </Form.Group>
          </Form>
        </Col>
      </Row>

      <Row className="mt-3">
        <Col>
          <Button
            variant="primary"
            // href="/my_account/edit"
            onClick={handeChangeClick}
            block
          >
            <FormattedMessage
              id="change_details_label"
              defaultMessage="Change Mobile No."
            />
          </Button>
        </Col>
        <Col>
          <Button
            variant="primary"
            block
            onClick={(e) => props.history.push("/my_account/change_language")}
          >
            <FormattedMessage
              id="change_language"
              defaultMessage="Change Language"
            />
          </Button>
        </Col>
      </Row>
      <Row>
        <Col className={"mt-3"}>
          <Button
            variant="primary"
            block
            onClick={(e) => props.history.push("/my_account/sell_history")}
          >
            <FormattedMessage id="sell_history" defaultMessage="Sell History" />
          </Button>
        </Col>
      </Row>
      <Row>
        <Col className={"mt-3"}>
          <Button variant="danger" block onClick={onHandleLogout}>
            <FormattedMessage id="logout" defaultMessage="Logout" />
          </Button>
        </Col>
      </Row>
      <Row>
        <Col className={"mt-3"}>
          <Alert variant="warning">
            <FormattedMessage
              id="account_edit_warning"
              defaultMessage="To change any other details, please visit nearest GramHeet Mandi."
            />
          </Alert>
        </Col>
      </Row>
    </Container>
  );
}

export default MyAccount;
