import React, { useContext, useEffect, useState } from "react";
import { Container, Row, Col, Form, Button, Image } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import { Context } from "../Layout/Default";


function Home() {
  const context = useContext(Context);  

  return (
    <Container className="mt-5">
      <Row style={{ textAlign: "center" }}>
        <Col>
          <Image src="grammadi.png" fluid />
        </Col>
      </Row>

      <Row className="my-5 text-center">
        <Col>
          <h4>
            <FormattedMessage
              id="home_welcome"
              defaultMessage="Welcome to GramHeet"
            />
          </h4>
        </Col>
      </Row>

      <Row className="mt-5 mx-auto language-container">
        <Col className="language-label">
          <Form.Label>
            <FormattedMessage
              id="home_choose_language"
              defaultMessage="Choose your language"
            />
          </Form.Label>
        </Col>
        <Col xs={12} className="language-selection">
          <Form.Group controlId="languageForm.ControlLanguage">
            <Form.Control
              as="select"
              value={context.locale}
              onChange={context.switchLocale}
            >
              <option value="en-IN">English</option>
              <option value="mr-IN">मराठी</option>
              <option value="te-IN">తెలుగు</option>
            </Form.Control>
          </Form.Group>
        </Col>
        <Col xs={12} className="my-5 navigation-next">
          <Form.Group
            controlId="formGroupMobile"
            style={{ textAlign: "center" }}
          >
            <Button size="block" variant="primary" href="/login">
              <FormattedMessage id="button_next" defaultMessage="Next" />
            </Button>
          </Form.Group>
        </Col>
      </Row>
    </Container>
  );
}

export default Home;
