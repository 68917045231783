import React, { useEffect, useContext } from "react";
import { Container, Row, Col, Accordion, Card, Button } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import { Context } from "../../Layout/Default";

function CreditFaq(props) {
  const context = useContext(Context);

  useEffect(() => {
    context.updateNavigationTitle("Credit FAQ");
  }, []);

  return (
    <Container className={"my-3 faq-container"}>
      {/* <Row className={!toggle ? 'd-block' : 'd-none'}> */}
      <Row>
        <Col>
          <h5>
            <FormattedMessage
              id="credit_faq_info_1"
              defaultMessage="Due to the immediate need for money just after the harvest, farmers have to sell their
              crops at the prevailingprices. Most of the time this price is very low. Keeping this in
              mind, through GramMandi, a mortgage loan facility is provided to the farmers for the
              storage of their agricultural produce. To know more about GramMandi’s credit facility,
              click on the questions below."
            />
          </h5>

          {/* <h4 onClick={onHandleClick}>तारण कर्जा विषयक माहिती </h4> */}
        </Col>
      </Row>

      {/* <Row className={toggle ? 'd-block' : 'd-none'}> */}
      <Row>
        <Col>
          <Accordion defaultActiveKey="0">
            <Card>
              <Card.Header>
                <Accordion.Toggle as={Button} variant="link" eventKey="0">
                  <FormattedMessage
                    id="credit_faq_question_1"
                    defaultMessage="Is there a compulsion on the farmer to take the credit if he stores the
                    produce at GramMandi?"
                  />
                </Accordion.Toggle>
              </Card.Header>
              <Accordion.Collapse eventKey="0">
                <FormattedMessage
                  id="credit_faq_question_1_answer"
                  defaultMessage="No. It is an independent decision of the farmer whether to take credit or not. GramMandi
                    provides the same set of services to the farmers taking credit and farmers not taking
                    credit."
                />
              </Accordion.Collapse>
            </Card>

            <Card>
              <Card.Header>
                <Accordion.Toggle as={Button} variant="link" eventKey="1">
                  <FormattedMessage
                    id="credit_faq_question_2"
                    defaultMessage="How the credit amount against the produce is decided?"
                  />
                </Accordion.Toggle>
              </Card.Header>
              <Accordion.Collapse eventKey="1">
                <Card.Body>
                  <FormattedMessage
                    id="credit_faq_question_2_answer"
                    defaultMessage="The day farmer brings produce for storage, 50% of the total amount as per the per
                    quintal price of that day is given as credit to the farmer.
                    For eg. Today, A farmer named Narayan Reddy brings 20 quintals of groundnut for
                    storage and wants credit against the same. If the market price of today is Rs.6000 per
                    quintal then the total value of Narayan’s produce is Rs. 1,20,000. So, Narayan is liable
                    to get a credit of 50% of the total value of his produce which is Rs. 60,000."
                  />
                </Card.Body>
              </Accordion.Collapse>
            </Card>

            <Card>
              <Card.Header>
                <Accordion.Toggle as={Button} variant="link" eventKey="2">
                  <FormattedMessage
                    id="credit_faq_question_3"
                    defaultMessage="What is the interest rate on the credit provided by GramMandi?"
                  />
                </Accordion.Toggle>
              </Card.Header>
              <Accordion.Collapse eventKey="2">
                <Card.Body>
                  <FormattedMessage
                    id="credit_faq_question_3_answer"
                    defaultMessage="GramMandi has partnered with various financial institutions across India to provide
                    credit to farmers at lower interest rates. The interest rate differs with every financial
                    institution. Clear and detailed information regarding the interest rate is given to the
                    farmer in person when he/she comes to store the produce."
                  />
                </Card.Body>
              </Accordion.Collapse>
            </Card>

            <Card>
              <Card.Header>
                <Accordion.Toggle as={Button} variant="link" eventKey="3">
                  <FormattedMessage
                    id="credit_faq_question_4"
                    defaultMessage="Is there any relation between GramMandi’s credit facility and Government
                    Pledge Finance Schemess?"
                  />
                </Accordion.Toggle>
              </Card.Header>
              <Accordion.Collapse eventKey="3">
                <Card.Body>
                  <FormattedMessage
                    id="credit_faq_question_4_answer"
                    defaultMessage="No."
                  />
                </Card.Body>
              </Accordion.Collapse>
            </Card>

            <Card>
              <Card.Header>
                <Accordion.Toggle as={Button} variant="link" eventKey="4">
                  <FormattedMessage
                    id="credit_faq_question_5"
                    defaultMessage="Can farmers take the credit and make the repayment in installments ?"
                  />
                </Accordion.Toggle>
              </Card.Header>
              <Accordion.Collapse eventKey="4">
                <Card.Body>
                  <FormattedMessage
                    id="credit_faq_question_5_answer"
                    defaultMessage="Farmers can take the credit in installments as per their need. But the repayment must
                    be done at a single time. When the farmers sell their produce, the amount of credit
                    taken, interest on credit, and warehouse rent is deducted from the total receivable
                    amount. The remaining amount is directly transferred to the bank account of the farmer."
                  />
                </Card.Body>
              </Accordion.Collapse>
            </Card>

            <Card>
              <Card.Header>
                <Accordion.Toggle as={Button} variant="link" eventKey="5">
                  <FormattedMessage
                    id="credit_faq_question_6"
                    defaultMessage="If a farmer wants to sell their stored produce, does he/she required to make
                    the repayment of credit with interest before he/she sell the produce?"
                  />
                </Accordion.Toggle>
              </Card.Header>
              <Accordion.Collapse eventKey="5">
                <Card.Body>
                  <FormattedMessage
                    id="credit_faq_question_6_answer"
                    defaultMessage="No. To sell their stored produce farmers are not required to make the repayment of
                    credit and interest on it. Farmers are not required to come to the warehouse to sell their
                    produce. Farmers can use the App or give a phone call to the warehouse to sell the
                    produce. The total amount received after the online sale of the produce after deducting
                    the amount of credit with interest and rent of the warehouse is directly sent to the bank
                    account of the farmer."
                  />
                </Card.Body>
              </Accordion.Collapse>
            </Card>
          </Accordion>
        </Col>
      </Row>
    </Container>
  );
}

export default CreditFaq;
