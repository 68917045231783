import React, { useState, useEffect, useContext } from "react";
import {
  Container,
  Row,
  Col,
  Form,
  Button,
  Toast,
  Alert,
  Image,
} from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import { verifyOtp, resendOtp } from "../../services/otp";
import OtpInput from "react-otp-input";
import { confirmCreditRequest } from "../../services/Credit";
import { confirmSell } from "../../services/MyGodown";
import { getAccountDataBYMobile, updateFarmer } from "../../services/Account";
import { Context } from "../Layout/Default";

function Authenticate(props) {
  const context = useContext(Context);

  const [showFail, setShowFail] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [showResend, setShowResend] = useState(false);
  const [msg, setMsg] = useState(".");

  const [errorMsg, setErrorMsg] = useState("There was error in request!");
  const [resendMsg, setResendMsg] = useState("OTP resent succesfully!");

  const [otp, setOtp] = useState();

  const otpMessageMapping = {
    registration: {
      id: "alert_otp_fill_for_registration",
      defaultMessage:
        "To complete the registration on Gramheet please enter 4 digit OTP in following box",
      successId: "alert_registration_otp_success",
      success:
        "Congratulations! Registration on Gram Mandi us complete! Welcome to GramHeet family!",
    },
    credit: {
      id: "alert_otp_fill_for_credit",
      defaultMessage:
        "To confirm the Credit Request please enter 4 digit OTP in following box",
      successId: "alert_credit_otp_success",
      success:
        "Congratulations! Your credit request is succesfull! Credit Request No : {invoice}",
    },
    sell: {
      id: "alert_otp_fill_for_sell",
      defaultMessage:
        "Please enter 4 digit OTP received on your mobile to confirm your sell",
      successId: "alert_sell_otp_success",
      success:
        "Congratulations! Your sell is confirmed. Sell Invoice No. is : {invoice}",
    },
    account_update: {
      id: "alert_otp_fill_for_account_update",
      defaultMessage:
        "To update your mobile number, enter 4 digit OTP received on your new number.",
      successId: "alert_update_otp_success",
      success: "Congratulations! Mobile number was successfully updated.",
    },
  };

  let formattedMsgId = otpMessageMapping.registration.id;
  let formattedDefaultMsg = otpMessageMapping.registration.defaultMessage;
  let formattedSuccessId = otpMessageMapping.registration.successId;
  let formattedSuccessMsg = otpMessageMapping.registration.success;

  let values = {};

  console.log(props.location);

  switch (props.location.from) {
    case "registration":
      formattedMsgId = otpMessageMapping.registration.id;
      formattedDefaultMsg = otpMessageMapping.registration.defaultMessage;
      formattedSuccessId = otpMessageMapping.registration.successId;
      formattedSuccessMsg = otpMessageMapping.registration.success;
      break;
    case "login":
      formattedMsgId = otpMessageMapping.registration.id;
      formattedDefaultMsg = otpMessageMapping.registration.defaultMessage;
      formattedSuccessId = otpMessageMapping.registration.successId;
      formattedSuccessMsg = otpMessageMapping.registration.success;
      break;
    case "credit":
      formattedMsgId = otpMessageMapping.credit.id;
      formattedDefaultMsg = otpMessageMapping.credit.defaultMessage;
      formattedSuccessId = otpMessageMapping.credit.successId;
      formattedSuccessMsg = otpMessageMapping.credit.success;
      break;
    case "sell":
      formattedMsgId = otpMessageMapping.sell.id;
      formattedDefaultMsg = otpMessageMapping.sell.defaultMessage;
      formattedSuccessId = otpMessageMapping.sell.successId;
      formattedSuccessMsg = otpMessageMapping.sell.success;
      break;
    case "account_update":
      formattedMsgId = otpMessageMapping.account_update.id;
      formattedDefaultMsg = otpMessageMapping.account_update.defaultMessage;
      formattedSuccessId = otpMessageMapping.account_update.successId;
      formattedSuccessMsg = otpMessageMapping.account_update.success;
      break;

    default:
      break;
  }

  const handleOtpVerify = async () => {
    let redirect = "/dashboard";

    let farmer_id = null;

    try {
      const verificationResult = await verifyOtp(
        otp,
        "91" + props.location.mobile
      );
      console.log("verificationResult:");
      console.log(verificationResult);

      if (verificationResult.data.type == "success") {
        if (props.location.from == "login") {
          redirect = "/fpo";

          const farmerResult = await getAccountDataBYMobile(
            props.location.mobile
          );

          console.log("farmerResult:");
          console.log(farmerResult);

          if (farmerResult.data.success && farmerResult.data.farmer_data) {
            context.setFarmerId(farmerResult.data.farmer_data.id);
            context.setFarmerMobile(props.location.mobile);
            localStorage.setItem(
              "gramheet.fpo_id",
              farmerResult.data.farmer_data.organisation_id
            );
            redirect = "/dashboard";
          }
        }

        if (props.location.from == "credit") {
          const result = await fetchCreditConfirm();
          if (result.data.success) {
            setMsg(result.data.data.code);
            setShowSuccess(true);
          } else {
            // TO DO
          }
        }

        if (props.location.from == "sell") {
          const result = await fetchSellConfirm();
          if (result.data.success) {
            setMsg(result.data.sell_data.invoice);
            setShowSuccess(true);
          } else {
            // TO DO
          }
        }

        if (props.location.from == "account_update") {
          const result = await updateMobileNumber({
            farmer_id: props.location.farmer_id,
            new_mobile: props.location.mobile,
            old_mobile: props.location.old_mobile,
          });
          if (result.data.success) {
            //setMsg(result.data.sell_data.invoice)
            setShowSuccess(true);
          } else {
            // TO DO
          }
        }

        setTimeout(() => {
          props.history.push({
            pathname: redirect,
            mobile: props.location.mobile,
            farmer_id: farmer_id,
          });
        }, 5000);
      } else {
        setErrorMsg(verificationResult.data.message);
        setShowFail(true);
      }
    } catch (err) {
      context.setShowError(true);
    }
  };

  const fetchCreditConfirm = async () => {
    const result = await confirmCreditRequest(props.location.creditReqObj);
    return result;
  };

  const fetchSellConfirm = async () => {
    const result = await confirmSell(props.location.storage_id);
    return result;
  };

  const updateMobileNumber = async (reqBody) => {
    const result = await updateFarmer(reqBody);
    return result;
  };

  useEffect(() => {
    //console.log(props.location)
  });

  const handleChange = (otp) => setOtp(otp);

  const handleOtpResend = async () => {
    const resendResult = await resendOtp("91" + props.location.mobile);
    if (resendResult.data.type == "success") {
      setResendMsg("OTP sent successfully.");
      setShowResend(true);
    } else {
      setErrorMsg(resendResult.data.message);
      setShowFail(true);
    }
  };

  const parseOTP = (otp) => {
    console.log(otp);
  };

  const preventTyping = (e) => {
    console.log(e.target);
  };

  return (
    <Container fluid className="mt-5">
      <Row className="my-5" style={{ textAlign: "center" }}>
        <Col>
          <Image src="grammadi.png" fluid />
        </Col>
      </Row>
      <Row>
        <Col className={"text-center"}>
          <h3>
            <FormattedMessage
              id="header_otp_confirmation"
              defaultMessage="OTP Confirmation"
            />
          </h3>
        </Col>
      </Row>
      <Row>
        <Col className={"text-center"}>
          <Alert variant="warning">
            <FormattedMessage
              id={formattedMsgId}
              defaultMessage={formattedDefaultMsg}
              values={values}
            />
          </Alert>
        </Col>
      </Row>
      <Row>
        <Col>
          <Form>
            <Form.Group
              className={"otp-wrapper my-4"}
              controlId="formGroupEnterOtp"
            >
              <OtpInput
                value={otp}
                onChange={handleChange}
                numInputs={4}
                separator={<span>-</span>}
                isInputNum={true}
              />
            </Form.Group>

            <Form.Group
              controlId="formGroupButton"
              style={{ textAlign: "center" }}
            >
              <Button size="block" variant="primary" onClick={handleOtpVerify}>
                <FormattedMessage id="button_submit" defaultMessage="Submit" />
              </Button>
            </Form.Group>

            <Form.Group
              controlId="formGroupButton"
              style={{ textAlign: "center" }}
            >
              <Button size="block" variant="primary" onClick={handleOtpResend}>
                <FormattedMessage
                  id="button_resend"
                  defaultMessage="Resend OTP"
                />
              </Button>
            </Form.Group>
          </Form>
        </Col>
      </Row>
      <Row>
        <Col>
          <Toast
            onClose={() => setShowFail(false)}
            show={showFail}
            delay={5000}
            autohide
          >
            <Toast.Header>
              <strong className="mr-auto">
                <FormattedMessage
                  id="otp_failure"
                  defaultMessage="OTP Failure!"
                />
              </strong>
            </Toast.Header>
            <Toast.Body>{errorMsg}</Toast.Body>
          </Toast>
        </Col>
      </Row>
      <Row>
        <Col>
          <Toast
            onClose={() => setShowSuccess(false)}
            show={showSuccess}
            delay={5000}
            autohide
          >
            <Toast.Header>
              <strong className="mr-auto">
                <FormattedMessage
                  id="operation_success"
                  defaultMessage="Operation Successfully!"
                />
              </strong>
            </Toast.Header>
            <Toast.Body>
              <FormattedMessage
                id={formattedSuccessId}
                defaultMessage={formattedSuccessMsg + msg}
              />
            </Toast.Body>
          </Toast>
        </Col>
      </Row>

      <Row>
        <Col>
          <Toast
            onClose={() => setShowResend(false)}
            show={showResend}
            delay={5000}
            autohide
          >
            <Toast.Header>
              <strong className="mr-auto">
                <FormattedMessage
                  id="operation_success"
                  defaultMessage="Operation Successfully!"
                />
              </strong>
            </Toast.Header>
            <Toast.Body>{resendMsg}</Toast.Body>
          </Toast>
        </Col>
      </Row>
    </Container>
  );
}

export default Authenticate;
