import React, { useState, useContext, useEffect } from "react";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import { getQualityOptions } from "../../../services/lab";
import { lowerCase } from "lower-case";
import { useHistory } from "react-router-dom";
import { FormattedMessage, useIntl } from "react-intl";
import { Context } from "../../Layout/Default";

function LabResultOption(props) {
  const context = useContext(Context);
  let history = useHistory();
  const intl = useIntl();

  const [option, setOption] = useState({});
  const [sweight, setSweight] = useState();
  const [optionData, setDptionData] = useState({});

  useEffect(() => {
    context.updateNavigationTitle("Quality Testing");

    let cropId = props.location.crop_id;

    if (cropId !== undefined) {
      localStorage.setItem("lab_crop_id", cropId);
    } else {
      cropId = localStorage.getItem("lab_crop_id");
    }
    let cropName = props.location.crop_name;

    if (cropName !== undefined) {
      localStorage.setItem("lab_crop_name", cropName);
    }

    async function fetchData() {
      try {
        const result = await getQualityOptions(cropId);
        return result;
      } catch (err) {
        context.setShowError(true);
      }
    }

    fetchData().then((res) => setOption(res.data));
  }, [setOption]);

  return (
    <Container fluid className="mt-4" style={{ margin: "0 auto" }}>
      {typeof props.location.crop_name != "undefined" && (
        <Row>
          <Col>
            <h3 className={"mb-4 text-center"}>
              <FormattedMessage
                id={props.location.crop_name
                  .split(" ")
                  .join("_")
                  .toLocaleLowerCase()}
                defaultMessage={props.location.crop_name}
              />
            </h3>
          </Col>
        </Row>
      )}
      <Row>
        <Col>
          <Form.Group controlId="formGroupSampleWeight">
            <Form.Label>
              <FormattedMessage
                id="sample_weight"
                defaultMessage="Sample Weight"
              />
            </Form.Label>
            <Form.Control
              type="number"
              placeholder={
                intl.formatMessage({
                  id: "label_sample_weight",
                  defaultMessage: "Sample Weight",
                }) +
                " " +
                intl.formatMessage({ id: "enter", defaultMessage: "Enter" })
              }
              value={sweight}
              onChange={(e) => {
                setSweight(e.target.value);
              }}
            />
          </Form.Group>
        </Col>
      </Row>
      <Row className="mt-2">
        <Col>
          {Object.keys(option).length
            ? Object.keys(option).map((opt) => {
                return (
                  <Form key={opt}>
                    <Form.Group
                      controlId={`formGroup${lowerCase(option[opt])}`}
                    >
                      <Form.Label>
                        <FormattedMessage
                          id={context.formatSlug(option[opt])}
                          defaultMessage={option[opt]}
                        />
                      </Form.Label>
                      <Form.Control
                        type="number"
                        placeholder={intl.formatMessage(
                          {
                            id: "fill_in_parameter",
                            defaultMessage: option[opt],
                          },
                          {
                            parameter: intl.formatMessage({
                              id: context.formatSlug(option[opt]),
                              defaultMessage: option[opt],
                            }),
                          }
                        )}
                        onChange={(e) => {
                          setDptionData({
                            ...optionData,
                            [opt]: e.target.value,
                          });
                        }}
                      />
                    </Form.Group>
                  </Form>
                );
              })
            : ""}
          <Form.Group
            controlId="formGroupHistory"
            style={{ textAlign: "center" }}
          >
            <Button
              size="block"
              variant="info"
              onClick={(e) => {
                history.push({
                  pathname: "/labs/results",
                  optionData: optionData,
                  option: option,
                  weight: sweight,
                  crop_id: props.location.crop_id
                    ? props.location.crop_id
                    : localStorage.getItem("lab_crop_id"),
                  crop_name: props.location.crop_name
                    ? props.location.crop_name
                    : localStorage.getItem("lab_crop_name"),
                });
              }}
            >
              <FormattedMessage id="button_submit" defaultMessage="Submit" />
            </Button>
          </Form.Group>
        </Col>
      </Row>
    </Container>
  );
}

export default LabResultOption;
