import { useEffect } from "react";
import { useIntl } from "react-intl";

export default ({ priceAlertData, show }) => {
  const intl = useIntl();

  const getTranslatedCropName = () =>
    intl.locale === "en-IN"
      ? priceAlertData.crop
      : priceAlertData.secondary_crop;

  useEffect(() => {
    if (show)
      if (window.Android) {
        window.Android.showToast(
          intl.formatMessage(
            {
              id: "price_alert",
            },
            {
              crop: priceAlertData.crop,
              price: priceAlertData.price,
            }
          )
        );
      }
  }, [show]);

  const getTranslatedMessage = () => {
    return intl.formatMessage(
      {
        id: "price_alert",
      },
      {
        crop: getTranslatedCropName(),
        price: priceAlertData.price,
      }
    );
  };

  return show ? (
    <div className="price-alert">{getTranslatedMessage()}</div>
  ) : (
    <></>
  );
};
