import React, { useContext } from "react";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import { Context } from "../../Layout/Default";

function ChangeLanguage() {
  const context = useContext(Context);

  return (
    <Container className="my-3">
      <Row className="mt-5 mx-auto language-container">
        <Col className="language-label">
          <Form.Label>
            <FormattedMessage
              id="home_choose_language"
              defaultMessage="Choose your language"
            />
          </Form.Label>
        </Col>
        <Col xs={12} className="language-selection">
          <Form.Group controlId="languageForm.ControlLanguage">
            <Form.Control
              as="select"
              value={context.locale}
              onChange={context.switchLocale}
            >
              <option value="en-IN">English</option>
              <option value="mr-IN">मराठी</option>
              <option value="te-IN">తెలుగు</option>
            </Form.Control>
          </Form.Group>
        </Col>
      </Row>
    </Container>
  );
}

export default ChangeLanguage;
