import React, { useState, useEffect, useContext } from 'react';
import {Container, Row, Col, Table, Card, Accordion} from 'react-bootstrap'
import { getStorages } from '../../../services/MyGodown'
import { Link } from "react-router-dom";
import { FormattedMessage, useIntl } from 'react-intl'
import { Context } from "../../Layout/Default";

function Storage(props) {
  const context = useContext(Context);
  const intl = useIntl()

  const [storages, setStorages] = useState([]);

  let farmer_id = props.match.params.farmer_id;
  let crop_id = props.match.params.crop_id;
  let crop_name = props.match.params.crop_name;


  useEffect( () => {
    context.updateNavigationTitle("{crop} Storage Receipts", 'crop_storage_receipts', {crop: intl.formatMessage({id: context.formatSlug(crop_name), defaultMessage: crop_name })})

    async function fetchData() {
      try {
        const result = await getStorages(farmer_id, crop_id);
        return result;
      } catch (err) {
        context.setShowError(true)
      }
    }

    fetchData().then(res => setStorages(res.data.storages))

  }, [setStorages]);

  return (
    <Container className="my-3">
      <Row>
        <Col>
          <Accordion defaultActiveKey="0">
            {storages.map((storage,_i) => {
              return (
                <Card key={storage.id}>
                  <Card.Header className="text-center">
                    <Accordion.Toggle as={Card.Header} eventKey={`${_i}`}>
                      <FormattedMessage id="receipt_no" defaultMessage="Receipt No." /> {storage.invoice}
                    </Accordion.Toggle>
                  </Card.Header>
                  <Accordion.Collapse eventKey={`${_i}`}>
                    <Card.Body>
                      <Table striped bordered hover >
                        <tbody>
                          <tr>
                            <th>
                              <FormattedMessage
                                id="storage_quantity"
                                defaultMessage="Quantity" // Message should be a string literal
                              />
                            </th>
                            <td>{context.formatWeight(intl, storage.quantity)}</td>
                          </tr>
                          <tr>
                            <th>
                              <FormattedMessage
                                id="date_of_storage_label"
                                defaultMessage="Date of Storage" // Message should be a string literal
                              />
                            </th>
                            <td>{storage.storage_date}</td>
                          </tr>
                          <tr>
                            <th>
                              <FormattedMessage
                                id="validity_to_sell_label"
                                defaultMessage="Validity to Sell" // Message should be a string literal
                              />
                            </th>
                            <td>{storage.validity}</td>
                          </tr>
                          <tr>
                            <td colSpan="2" className="text-center"><Link to={{ pathname: `/my_godown/storage/view/${storage.id}`, crop_name: crop_name, storage_invoice: storage.invoice }}>
                              <FormattedMessage
                                id="view_details"
                                defaultMessage="View Details" // Message should be a string literal
                              />
                            </Link></td>
                          </tr>
                        </tbody>
                      </Table>
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
              );
            })}
          </Accordion>
        </Col>
      </Row>
    </Container>)
}

export default Storage;
