import React, { useContext, useEffect, useState } from 'react'
import {Container, Row, Col, Accordion, Table, Card, Alert} from 'react-bootstrap'
import { getSellHistory } from '../../../services/Account'
import { Context } from "../../Layout/Default";
import { Link } from 'react-router-dom'
import { FormattedMessage } from 'react-intl'

function SellHistory(props) {

    const context = useContext(Context)

    const [sellHistory, setSellHistory] = useState([])


    useEffect( () => {
        context.updateNavigationTitle('Sell History')

        async function fetchData() {
            try {
                const historyResult = await getSellHistory(context.getFarmerId())
                return historyResult
            } catch (err) {
                context.setShowError(true)
            }
        }

        fetchData().then(res => setSellHistory(res.data))

    }, [setSellHistory])
    return (
        <Container className="my-2">
            <Row>
                <Col>
                    {sellHistory.length ?
                        <Accordion defaultActiveKey="0">
                            {
                                sellHistory.map((historyObj, i) => {
                                    return <Card key={"sell-history-item-"+i}>
                                        <Accordion.Toggle as={Card.Header} variant="link" eventKey={`${i}`} className='text-center'>

                                            <FormattedMessage
                                                id="receipt_number"
                                                defaultMessage="Receipt No."
                                            /> {historyObj.invoice}
                                        </Accordion.Toggle>
                                        <Accordion.Collapse eventKey={`${i}`}>
                                            <Table bordered>
                                                <tbody>
                                                <tr>
                                                    <th>
                                                        <FormattedMessage
                                                            id="table_cell_crop_name"
                                                            defaultMessage="Crop Name"
                                                        />
                                                    </th>
                                                    <td><FormattedMessage
                                                        id={context.formatSlug(historyObj.crop_name)}
                                                        defaultMessage={historyObj.crop_name}
                                                    /></td>
                                                </tr>
                                                <tr>
                                                    <th><FormattedMessage
                                                        id="table_cell_date_of_cell"
                                                        defaultMessage="Date Of Sale"
                                                    /></th>
                                                    <td>{historyObj.date_of_sell}</td>

                                                </tr>
                                                <tr>
                                                    <th><FormattedMessage
                                                        id="table_cell_status"
                                                        defaultMessage="Status"
                                                    /></th>
                                                    <td><FormattedMessage
                                                        id={context.formatSlug(historyObj.status)}
                                                        defaultMessage={historyObj.status}
                                                    /></td>

                                                </tr>
                                                {/* <tr>
                                                    <th><FormattedMessage
                                                        id="table_cell_download_pdf"
                                                        defaultMessage="Download PDF Receipt"
                                                    /></th>
                                                    <td>
                                                        {historyObj.pdf_url != "" ? (
                                                            <Link download to={{ pathname: historyObj.pdf_url }} target="_blank">{`${historyObj.invoice}.pdf`}</Link>
                                                            ) : (
                                                                <FormattedMessage
                                                                    id="unavailable"
                                                                    defaultMessage="Not Available"
                                                                />
                                                            )}
                                                    </td>
                                                </tr> */}
                                                </tbody>
                                            </Table>
                                        </Accordion.Collapse>
                                    </Card>

                                })
                            }
                        </Accordion>
                        :
                        <Row>
                            <Col>
                                <Alert variant='info'>
                                    <FormattedMessage
                                        id="info_no_crop_sold"
                                        defaultMessage="You have not sold any crop as of today"
                                    />
                                </Alert>
                            </Col>
                        </Row>}
                </Col>
            </Row>
        </Container >
    )
}

export default SellHistory